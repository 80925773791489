import React, { useEffect, useState } from 'react';
import useAjaxhook from "../CustomHooks/useAjaxhook";
import MaterialTable from 'material-table';
import Table from "../../ownComponents/Table";
import CustomRequestLoader from "../customloader/CustomRequestLoader";
import ServiceModal from './ServiceModel';
import axios from "axios";
import moment from 'moment';


import Swal from 'sweetalert2';
import Select from 'react-select';
import $ from "jquery";
import { sortedLastIndexOf } from 'lodash';

const Services = () => {

  const headerStyle = {
    backgroundColor: '#5939B6',
    color: '#fff',
    fontSize: '14px',
    fontWeight: 'normal',
    padding: '10px',
    position: 'sticky',
    top: 0 ,
  }
  const rowStyle={
    // fontSize:'12px',
    // padding:'10px',
    // color:'#818698',
    zIndex:0
  }

  const URL="https://datingapi.lipsticksncufflinks.com"

  const [reservation, setReservation] = useState([]); //all reservation data table 1
  const [specialRequest, setSpecialRequest] = useState([]);//all specialRequest data table 2

  const [serviceType, setServiceType] = useState([]) //dropdown service request type select 1
  const [selectedType,setSelectedType] = useState('')

  const [ResvReqName, setResvReqName] = useState([]) // reservation request name
  const [selectedReqName, setSelectedReqName] = useState('') 

  const [SpeclReqName, setSpeclReqName] = useState([]);//special-request name 
  const [selectedSpeclReqName, setSelectedSpeclReqName] = useState('') 

  const [tableStatus,setTableStatus] = useState('Service Table');

  const [load, setLoad] = useState(false) //table switch
  const [reqLoad, setreqLoad] = useState(false);

  const [editMode, setEditMode] = useState("");

  const [searchData, setSearchData] = useState([]);
  const { getalldata, updatedata, deletedata } = useAjaxhook();
  const [userData, setUserData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState("");
  const filterOpt=[{label:"Pending",value:"Pending"},{label:"Rejected",value:"Rejected"},{label:"Closed",value:"Closed"}]
  const columns = [
    
    { title: "User Name", field: "fullName" },
    { title: "Email", field: "userEmail" },
    { title: "Mobile", field: "mobileNo" },
    // { title: "Service Type", field: "reqMainType" },
    { title: "Service Name", field: `reqTypeDetails.reqTypeName`,render:rowData=>
    {
      if(rowData.reqMainType==="Reservation")
      {return (`${rowData.reqTypeDetails.reqTypeName} (R)`)}
      else
      {return (`${rowData.reqTypeDetails.reqTypeName} (SR)`)}
    } },
    { title: "Date", field: "createdAt", render:rowData=><span>{moment(new Date(rowData.createdAt)).format("DD-MM-YYYY")}</span>} ,
    { hidden:true,title: "Date", field: "createdAt", render:rowData=><span>{moment(new Date(rowData.createdAt)).format("DD-MM-YYYY")}</span>,defaultSort:'desc'} ,
    {
      title: "Status",field:"status", render: rowData => 
      <Select
      options={filterOpt}
      // className="zIndex1"
      isDisabled={rowData.status==="Pending"?false:true}
      value={{label:rowData.status,value:rowData.status}}
      defaultValue={{label:rowData.status,value:rowData.status}}
      onChange={(e)=>handleStatus(e,rowData)}
      />},
  ];

  const column = [
    { title: "Request Type", field: "type" },
    { title: "Request Name", field: "reqName" },

    // {
    //   title: "Status", render: rowData =>
    //     <input type='checkbox' checked={rowData.status}
    //       onChange={() => { handleStatus(rowData) }} />
    // },
  ];

  useEffect(() => {
    getAllSpecialRequests();
    // getAllReservations();
    // setDDvalues();
    // getResReqNames();
    // getAllTypes();
    // getServiceTypes();
  
}, []);


  const handleClose = () => {
    setShow(false);
    setModalData("");
    setEditMode("");
  };

  const handleShow = () => {
    setShow(true)
  };

  //all special request service data
  const getAllSpecialRequests = ()=>{
    // debugger
      axios.get(`${URL}/getAllSpecialRequests`)
      .then( async (response) => {
        if (response.data.status === 200) {
          const data = response.data.data;
          let reservation=[];
          data&&data.map(info=>{
            if(info.reqMainType==="Reservation"){
              reservation.push(info)
            }
          })
          console.log("Data in main Table",response)
          await setReservation(reservation)
          await setSpecialRequest(data);
        }
      });
  }
  
//modal data
  const getUserProfile = (data, keyName) => {
    if (data !== "") {
      setModalData(data);
      setEditMode(keyName);
      handleShow();
    }
  }

  const handleStatus = (e, data) => {
    debugger
    // console.log(e.target.value);
    // console.log("Reservation: ",reservation)
    // const index = data.tableData.id;
    const status = e.value;
    // const update = [...searchData];
    // update[index] = { ...data, status: status };
    const id = data._id;

    let formData={"status":status};
    // const url = "${URL}/updateReqById/" + id;
    // axios.put(`${URL}/updateReqById/${id}`)
    // const ajax = updatedata(url, { status: status });
    // ajax.then((response) => {
    //   if (response.data.status === 200) {
    //     setSearchData(update);
    //     setLoader(false);
    //     const newData = reservation.map((obj) => {
    //       return obj._id === data._id ? { ...obj, status: status } : obj;
    //     });
    //     setReservation(newData);
    //   }
    //   else {
    //     setLoader(false);
    //   }
    // });

    // ajax.catch((error) => {
    //   if (error) {
    //     setLoader(false);
    //   }
    // });
    if(status!=="Pending"){Swal.fire({
      titleText: 'Are you sure?',
      text: "You won't be able to change status again...",
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-danger rounded-0 m-2 p-1 align-items-center',
        cancelButton: 'btn btn-dark rounded-0 m-2 p-1 align-items-center',
        title:"font-weight-light text-sm"
      },
      confirmButtonText: 'Yes'
    }).then((result) => {
      debugger
      if (result.isConfirmed) {

        // setLoader(true);
        axios.put(`${URL}/updateSpecialRequest/${id}`,{"status":status})
        .then(res=>{
          debugger
      if(res.status===200){
        debugger
        setLoader(false);
        // setSearchData(update);
        getAllSpecialRequests();
      }
      else{
        debugger
        setLoader(false);
        console.log(res)
        // Swal(res.message)
      }
    })
      }
      else{
        setLoader(false);
        
      }
      
      // setLoader(false);
    }).catch(e=>{
      debugger
      // setLoader(false);
      console.log(e)
    })}
    

  }
 
//status
  const handleSearchStatus = (event) => {
    const input = event.target;
    const index = event.tableData.id;
    const status = !event.status;
    const update = [...searchData];
    update[index] = { ...event, status: status };
    const id = reservation[index]._id;
    if (input.value !== "Select status") {
      if (input.value === "Pending") {
        const newData = reservation.filter((item, index) => {
          return item.status;
        });

        setReservation(newData);
      }

      if (input.value === "Reject") {
        const newData = reservation.filter((item, index) => {
          return item.status !== true;
        });

        setReservation(newData);
      }
    
    if (input.value === "Done") {
      const newData = reservation.filter((item, index) => {
        return item.status !== true;
      });

      setReservation(newData);
    }
  }

    else {
      setReservation([...reservation]);
    }
  }

//modal
  const handleModal = () => {
    handleShow();
    setModalData("");
    setEditMode("");
  }

  //delete request
  const deleteRequestResv = (index) => {
    const obj = ResvReqName[index];
    setLoader(true)
    axios.delete("https://datingapi.lipsticksncufflinks.com/deleteReqById/" + obj._id)
      .then((result) => {
        setResvReqName(result)
        window.location.href = "http://localhost:3000/services"
        setLoader(false)
      })
  }

  //delete request
  const deleteRequestSpecl = (index) => {
    const obj = SpeclReqName[index];
    setLoader(true)
    axios.delete("https://datingapi.lipsticksncufflinks.com/deleteReqById/" + obj._id)
      .then((result) => {
        setSpeclReqName(result)
        // window.location.href = "http://localhost:3000/services"
        setLoader(false)
      })
  }

  const addLatestRecord = (data) => {
    const newAllData = ResvReqName.concat(data);
    setResvReqName(newAllData);

    const sData = searchData.concat(data);
    setSearchData(sData);
  }

  const updatedataRequest = (data) => {
    const newData = ResvReqName.map((obj) => {
      return data.userId === obj.userId ? { ...obj, ...data } : obj;
    });

    const sData = searchData.map((obj) => {
      return data.userId === obj.userId ? { ...obj, ...data } : obj;
    });

    if (userData.userId === data.userId) {
      setUserData({ ...userData, ...data });
    }
    setResvReqName(newData);
    setSearchData(sData);
  }


  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-md-12">
          <div className="ms-panel">
            <div className="ms-panel-body">
              <div className="card rounded-0">
                <div className="card-body">
                  <div className="my-4">
                  <div className="d-flex justify-content-between align-items-center row py-2">
                   <div className="col"> 
                  <h4 className="p-1 m-0">Service Details</h4>
                  </div><div className="col"/>
                  {/* <div className="col"> 
                  <select disabled={tableStatus==="Service Table"?false:true} className="form-control rounded-0 mx-2" onChange={handleSearchStatus}>
                          <option>Select status</option>
                          <option>Pending</option>
                          <option>Reject</option>
                          <option>Done</option>
                        </select></div> */}
                  </div>

                    <div className="my-4 ">
                      {/* <MaterialTable
                        className="zIndex0"
                        title=""
                        columns={columns}
                        data={specialRequest}
                        options={{
                          actionsColumnIndex: -1
                        }}/>  */}
                        <Table 
                        pageSize={5} 
                        data={specialRequest}
                        paging={true}
                        columns={columns}
                        search={true} 
                        headerStyle={headerStyle}
                        rowStyle={rowStyle}
                        maxBodyHeight='300px'
                         />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div className={loader ? '' : 'd-none'}>
        <CustomRequestLoader />
      </div>

      <ServiceModal
        show={show}
        handleClose={handleClose}
        addLatestRecord={addLatestRecord}
        modalData={modalData}
        editMode={editMode}
        updatedataRequest={updatedataRequest}
      />
    </div>

  );

}

export default Services;


// table switch
  // const handleTableChange =(event)=>{
  //   const input = event.target;
  //   if (input.value !== "Select Table") {
  //     if (input.value === "Service Table") {
  //       setTableStatus('Service Table');
  //     }
  //     if (input.value === "Service Type Table") {
  //       setTableStatus('Service Type Table');
  //     }
  // }}

  //onChange for service type
  // const ind = document.querySelector(".s_services").selectedIndex;
    // const alloption = $(".s_services option");
    // const c_index = $(alloption[ind]).attr("index");
    // const userd = serviceType[c_index];
    // setDDvalues(userd);
    // console.log(userd);

  //Reservation Name
  // useEffect(() => {
  //   if (ResvReqName.length === 0) {
  //     const url = "https://datingapi.lipsticksncufflinks.com/getAllRequestByType/Reservation";
  //     const ajax = getalldata(url);
  //     ajax.then((response) => {
  //       if (response.data.status === 200) {
  //         const data = response.data.data;
  //         setResvReqName(data);
  //         setModalData(data);
  //       }
  //     });
  //   }
  // }, []);

  //special-Request name
  // useEffect(() => {
  //   if (SpeclReqName.length === 0) {
  //     const url = "https://datingapi.lipsticksncufflinks.com/getAllRequestByType/Special-Request";
  //     const ajax = getalldata(url);
  //     ajax.then((response) => {
  //       if (response.data.status === 200) {
  //         const data = response.data.data;
  //         setSpeclReqName(data);
  //       }
  //     });
  //   }
  // }, []);

  //------------For Filter-----------------//

  //reservation  name dropdown
  // const getResReqNames =()=>{
  //   axios.get(`${URL}/getAllSpecialRequests`)
  //   .then((response) => {
  //     if (response.data.status === 200) {
  //       const data = response.data.reqTypeDetails;
  //       let resNames=[];
  //       data&&data.map(info=>{
  //         resNames.push({value:info._id,label:info.reqName})
  //       })
        // console.log('Names DD',response.data.data.)
        // setResvReqName(resNames);
        // setModalData(data);
  //     }
  //   });
  // }

  // onChnage for request name 
  // const getRequestName = (selectedReqName) => {
    // const select = e.target;
    // const selectIndex = select.options.selectedIndex
  //   let value=selectedReqName.value;
  //   filterBySpclReqName(value);
  //   console.log(selectedReqName.value, "Selected Req");
  // }

  //onChange for service type (filter by Service)
  // const getServiceType = (selectedType) => {
  //   let value=selectedType.value;
  //   console.log('selected dd',selectedType.value)
  //   filterBySpclReq(value); 
  // }

  // useEffect(() => {
  //   const url = "https://datingapi.lipsticksncufflinks.com/getTypesForDropDown"
  //   axios.get(url)
  //     .then((response) => {
  //       const data = response.data.data;
  //       setServiceType(data);

  //     })
  // }, []);

  //all reservation service data (API not working)
  // const getAllReservations =()=>{  
  //   axios.get(`${URL}/getAllReservations`)
  //   .then((response) => {
  //     console.log("ALL RESERVATUIbs",response)
  //     if (response.data.status === 200) {
  //       const data = response.data.data;
  //       setReservation(data);
  //     }
  //   });
  // }

  // const getAllTypes=()=>{
  //   axios.get(`${URL}/getAllRequestTypes`)
  //     .then((response) => {
  //       const data = response.data.data;
  //       console.log("Data in Services Table", data)
  //       setSpeclReqName(data);
  //       setModalData(data);
  //     })
  // }

  // useEffect(() => {
  //   if (specialRequest.length === 0) {
  //     const url = "https://datingapi.lipsticksncufflinks.com/getAllSpecialRequests";
  //     const ajax = getalldata(url);
  //     ajax.then((response) => {

  //       if (response.data.status === 200) {
  //         console.log(response)
  //         const data = response.data.data;
  //         setSpecialRequest(data);
  //       }
  //     });
  //   }
  // }, []);

  //service type dropdown
  // const getServiceTypes=()=>{
  //   axios.get(`${URL}/getTypesForDropDown`)
  //     .then((response) => {
      
  //       let data = response.data.data;
  //       let services=[];
  //       data.map(srvc=>{
  //         services.push({value:srvc,label:srvc})
  //       })
  //       setServiceType(services);
  //       console.log("Drop Dowm",services)

  //     })
  // }
  
  // const filterBySpclReq=(value)=>{
    // debugger
      // axios.get(`${URL}/getAllSpecialRequests/`)
      // .then((response) => {
      //   if (response.data.status === 200) {
          
      //     const data = response.data.data;
      //     let filteredData=[]
      //     data&&data.map(inf=>{
      //       if(inf.reqMainType === value){
      //         filteredData.push(inf);
      //       }
      //     })
      //     setSpecialRequest(filteredData)
          // console.log('Filtered Data: ',filteredData)
          
  //       }
  //     });
  // }

  // const filterBySpclReqName=(value)=>{
    // debugger
  //     axios.get(`${URL}/getAllSpecialRequests/`)
  //     .then((response) => {
  //       if (response.data.status === 200) {
          
  //         const data = response.data.data;
  //         let filteredData=[]
  //         data&&data.map(inf=>{
  //           if(inf.reqTypeDetails.reqId === value){
  //             filteredData.push(inf);
  //           }
  //         })
  //         setSpecialRequest(filteredData)
  //         console.log('Filtered Data: ',filteredData)
          
  //       }
  //     });
  // }