import React,{useEffect,useState} from 'react';
import useAjaxhook from "../CustomHooks/useAjaxhook";
import {Link} from "react-router-dom";

const Dating=()=>
{
   const {getalldata} = useAjaxhook();
   useEffect(()=>{
     getAllTips();
   },[]);

   const [doCount,setDoCount] = useState(0);
   const [doesCount,setDoesCount] = useState(0);

   const getAllTips=()=>
   {
     const url = "https://datingapi.lipsticksncufflinks.com/getAllTips";
     const ajax = getalldata(url);
     ajax.then((response)=>{
        if(response.data.status === 200)
        {
           let doesc = 0;
           let dontc = 0;
           response.data.data.forEach((item)=>{
             if(item.tipsType === "Don't")
             {
                dontc+=1;
             }

             if(item.tipsType === "Do's")
             {
                doesc+=1;
             }
           });

           setDoCount(dontc);
           setDoesCount(doesc);
        }
     });
   }


   return(
     <div className="col-md-4 mb-5">
         <div className="card rounded-0 border-0 shadow-lg py-3">
         <div className="card-body container_box">
           <div className="dashboard_icon mb-3 d-flex align-items-center justify-content-center">
             <span className="material-icons text-danger" style={{fontSize:"40px"}}>
                favorite
              </span>
           </div>
           <h6 className="text-center">
             <Link to='/add-tips-content'>Datting Tips</Link>
           </h6>

          <div className="d-flex align-items-center justify-content-between">
             <div className="d-flex align-items-center">
               <h6 className="d-flex align-items-center justify-content-center p-0 m-0">Don't : {doCount}</h6>
             </div>

             <div className="d-flex align-items-center">
             <h6 className="d-flex align-items-center justify-content-center p-0 m-0">Do's : {doesCount}</h6>
            </div>
           </div>

         </div>
       </div>
     </div>
   )

}

export default Dating;
