import React from 'react';
import Modal from 'react-bootstrap/Modal';
const Imagemodel=(props)=>
{
    return(
      <div>
        <Modal show={props.show} onHide={props.handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex justify-content-center  w-100">
              <img src={props.currentImage} alt="tipsquotes" style={{width:"150px",height:"150px"}} className="rounded-circle" />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
}

export default Imagemodel;
