import React,{useState,useEffect} from 'react';
import $ from "jquery";
import "animate.css";
import CustomRequestLoader from "../customloader/CustomRequestLoader";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import "animate.css";

const RangeSliderUi=(props)=>
{

  const [loader,setLoader] = useState(false);
  const [editMode,setEditMode] = useState(false);
  const [msg,setMsg] = useState("");
  const [icon,setIcon] = useState("");
  const [methodType,setmethodType] = useState("POST");

  const [alldata,setAllData] = useState([]);
  const [questionData,setQuestion] = useState({
    question: "",
    questionMainType:"Compatibility",
    status:true,
    _id:null
  });

  const [inputData,setInputData] = useState([
    {
      optionQuestionText: "",
      startLabel: "",
      finalLabel: "",
      betweenLabel: null,
      startRange: "",
      finalRange: ""
    }
  ]);

  useEffect(()=>{

    if(Object.keys(props.questionData).length !== 0)
    {
        const queData = props.questionData;
        if(queData.questionType === "range-slider")
        {
          const QuestionOption = queData.options;
          setInputData(QuestionOption);

          const questionObj = {
            question:queData.question,
            questionMainType:queData.questionMainType,
            status:queData.status,
            _id:queData._id
          }
          setQuestion(questionObj);
          setEditMode(props.editMode);
        }

        else
        {
              setInputData([{
                    optionQuestionText: "",
                    startLabel: "",
                    finalLabel: "",
                    betweenLabel: null,
                    startRange: "",
                    finalRange: ""
                  }]);
              const questionObj = {
                question:"",
                questionMainType:"Compatibility",
                status:true,
                _id:null
              }
              setQuestion(questionObj);
              setEditMode(false);
              setmethodType("POST");
        }


    }



  },[props.questionData]);

  useEffect(()=>{
    if(questionData._id !==null && editMode===false)
    {
       setmethodType("PUT");
    }
  },[questionData,editMode]);

  useEffect(()=>{
    getData();
  },[]);

  const getData=()=>
  {
      const ajax = axios({
        method:"GET",
        url:"/allCompQuestions"
      });

      ajax.then((response)=>{
        const data = response.data.compQuestions;
        const tempData = [];
        data.forEach((item, i) =>
        {
           if(item.questionType === "range-slider")
           {
              tempData.push(item);
           }
        });
        setAllData(tempData);
      });
  }

  const addRemoveData=()=>
  {
      const newObj = {
            optionQuestionText: "",
            startLabel: "",
            finalLabel: "",
            betweenLabel: null,
            startRange: "",
            finalRange: ""
          }
      setInputData([...inputData,newObj]);
  }

  const removeUi=(index)=>
  {

     const updateData = inputData.filter((item,i) =>
     {
        return index !== i;
     });
     setInputData(updateData);

     const prevShowNum = index-1;
     const nextShowNum = index+1;
     $(".step_"+prevShowNum).removeClass("d-none");
     $(".step_"+nextShowNum).addClass("d-none");
     $(".step_"+index).addClass("d-none");
  }

  const nextSlider=(index)=>
  {
      const nextShowNum = Number(index)+1;
      $(".step_"+index).addClass("d-none");
      $(".step_"+nextShowNum).removeClass("d-none");
      $(".step_"+nextShowNum).addClass("animate__animated animate__slideInRight");
  }

  const prevSlider=(index)=>
  {
      const prevShowNum = Number(index)-1;
      $(".step_"+index).addClass("d-none");
      $(".step_"+prevShowNum).removeClass("d-none");
      $(".step_"+prevShowNum).addClass("animate__animated animate__slideInRight");
  }

  const handleInput=(event,index)=>
  {
     const name = event.target.name;
     const val  = event.target.value;
     const obj  = inputData[index];
     const newData = {...obj,[name]:val};
     const update = [...inputData];
     update[index] = newData;
     setInputData(update);
  }

  const handleQuestion=(event)=>
  {
     const name = event.target.name;
     const val = event.target.value;
     setQuestion({...questionData,[name]:val});
  }

  const ToggleMode=()=>
  {
     if(questionData._id !==null)
     {
        setEditMode(!editMode);
     }
  }

  const createRangeSlider=(event)=>
  {
     event.preventDefault();
     setLoader(true);
     let url = "";
     let method = "";
     if(methodType === "PUT")
     {
        method = "PUT";
        url = "/compQuestion/update/"+questionData._id;
     }

     if(methodType === "POST")
     {
        method = "POST";
        url = "/addCompQuestion/";
     }

     const ajax = axios({
       method:method,
       url:url,
       data:{
         "question":questionData.question,
          questionMainType:questionData.questionMainType,
          questionType: "range-slider",
          status:questionData.status,
          minOptions: inputData.length,
        "options": [
          ...inputData
            ],
       }
     });

     ajax.then((response)=>{
       setLoader(false);
       setmethodType("POST");
       if(response.data.status === 200)
       {
         setIcon("fa fa-check-circle");
         $(".toast_modal").removeClass("d-none");
         getData();
         $(".step_0").removeClass("d-none");
         if(method === "POST")
         {
           setMsg("Range slider questionnaire created");
         }
         else
         {
           setMsg("Range slider questionnaire edited");
         }
         setInputData([
           {
             optionQuestionText: "",
             startLabel: "",
             finalLabel: "",
             betweenLabel: null,
             startRange: "",
             finalRange: ""
           }
         ]);
         setQuestion({
           question: "",
           questionMainType:"Compatibility",
           status:true,
           _id:null
         });
         setTimeout(()=>{
           setIcon("");
           $(".toast_modal").addClass("d-none");
           setMsg("");
         },5000);
       }
       else
       {
         setIcon("fa fa-times-circle");
         $(".toast_modal").removeClass("d-none");
         setMsg("Something went wrong !");
         setTimeout(()=>{
           setIcon("");
           $(".toast_modal").addClass("d-none");
           setMsg("");
         },5000);
       }
     });

     ajax.catch((error)=>{
       setLoader(false);
       setIcon("fa fa-times-circle");
       $(".toast_modal").removeClass("d-none");
       setMsg("Something went wrong !");
       setTimeout(()=>{
         setIcon("");
         $(".toast_modal").addClass("d-none");
         setMsg("");
       },5000);
     });
  }

  const editViewQuestion=(event)=>
  {
     const index = event.target.value;
     if(index !== "All slider questionnaire")
     {
         const queData = alldata[index];
         const QuestionOption = queData.options;
         setInputData(QuestionOption);

         const questionObj = {
           question:queData.question,
           questionMainType:queData.questionMainType,
           status:queData.status,
           _id:queData._id
         }
         setQuestion(questionObj);
         $(".step_0").removeClass("d-none");
     }
  }

  const cancelEditing=()=>
  {
     props.propsData.history.push("/add-new-question");
     setmethodType("POST");
     setInputData([
       {
         optionQuestionText: "",
         startLabel: "",
         finalLabel: "",
         betweenLabel: null,
         startRange: "",
         finalRange: ""
       }
     ]);
     setQuestion({
       question: "",
       questionMainType:"Compatibility",
       status:true,
       _id:null
     });
     setEditMode(false);
     $(".step_0").removeClass("d-none");
  }

   return(
     <div className="px-2">
        <form onSubmit={createRangeSlider}>
          <div className="row mb-2">

            <div className="col-md-12 mb-2 d-flex align-items-center p-2" style={{backgroundColor:"#5939B6"}}>

              <h6 className="m-0 p-2 text-white w-50">
                Set range slider questionnaire
              </h6>

              <div className="d-flex align-items-center w-50">
                {questionData._id !== null &&
                <div className="custom-control custom-switch w-25">
                  <input type="checkbox" className="custom-control-input" checked={editMode} id="switch1" onChange={ToggleMode} />
                  <label className="custom-control-label text-white" htmlFor="switch1">Edit mode</label>
                </div>
                }

                <select className="form-control rounded-0 w-75" onChange={editViewQuestion}>
                  <option>All slider questionnaire</option>
                  {
                     alldata.map((data,index)=>(
                       <option key={index} value={index}>{data.question}</option>
                     ))
                  }
                </select>
              </div>

            </div>

            <div className="col-md-12 mb-2">
              <label>Question</label>
              <input type="text" name="question" value={questionData.question} onChange={handleQuestion} className="form-control rounded-0" placeholder="Enter your question here" disabled={editMode} required="required"/>
            </div>

            <div className="col-md-6 mb-2">
             <label>Question type</label>
             <select className="form-control rounded-0" name="questionMainType" value={questionData.questionMainType} onChange={handleQuestion} disabled={editMode}>
               <option>Compatibility</option>
               <option>Additional</option>
             </select>
           </div>

            <div className="col-md-6 mb-2">
              <label>Status</label>
              <select className="form-control rounded-0" name="status" value={questionData.status} onChange={handleQuestion} disabled={editMode}>
                <option value={true}>Active</option>
                <option value={false}>In Active</option>
              </select>
           </div>

          </div>
          {
            inputData.map((data,index)=>(
              <div className={`step_${index} ${index==0?'':'d-none'}`} key={index}>
                <div className="row mb-2">

                <div className="col-md-12 mb-2">
                  <h6 className="m-0 p-2 bg-info text-dark d-flex justify-content-between">
                    <span>Question option {index+1}</span>
                    {index !== 0 &&
                      <span className="material-icons mr-1 text-white" style={{fontSize:"22px",cursor:"pointer"}} onClick={()=>{removeUi(index)}}>
                        remove_circle_outline
                      </span>
                    }
                    <span className="material-icons mr-1 text-white" style={{fontSize:"22px",cursor:"pointer"}} onClick={addRemoveData}>
                      add_circle
                    </span>
                  </h6>
                </div>

                  <div className="col-md-6 mb-2">
                    <label>Between Label</label>
                    <input type="text" name="betweenLabel" value={inputData[index].betweenLabel===null?'': inputData[index].betweenLabel} onChange={(event)=>{handleInput(event,index)}} className="form-control rounded-0" placeholder="Question Text" disabled={editMode}/>
                  </div>

                 <div className="col-md-6 mb-2">

                  <div className="d-flex justify-content-between align-items-center">
                    <label>QuestionText</label>
                  </div>

                  <input type="text" name="optionQuestionText" value={inputData[index].optionQuestionText} onChange={(event)=>{handleInput(event,index)}} className="form-control rounded-0" placeholder="Question Text" disabled={editMode}/>
                 </div>

                 <div className="col-md-6 mb-2">
                  <label>Start Label</label>
                  <input type="text" name="startLabel" value={inputData[index].startLabel} onChange={(event)=>{handleInput(event,index)}} className="form-control rounded-0" placeholder="Start Label" disabled={editMode}/>
                </div>

                 <div className="col-md-6 mb-2">
                  <label>Final Label</label>
                  <input type="text" name="finalLabel" value={inputData[index].finalLabel} onChange={(event)=>{handleInput(event,index)}} className="form-control rounded-0" placeholder="Final Label" disabled={editMode}/>
                </div>

                 <div className="col-md-6 mb-2">
                  <label>Start Range</label>
                  <input type="number" name="startRange" value={inputData[index].startRange} onChange={(event)=>{handleInput(event,index)}} className="form-control rounded-0" placeholder="Start Ranage" disabled={editMode}/>
                </div>

                 <div className="col-md-6 mb-2">
                  <label>End Range</label>
                  <input type="number" name="finalRange" value={inputData[index].finalRange} onChange={(event)=>{handleInput(event,index)}} className="form-control rounded-0" placeholder="End Range" disabled={editMode}/>
                </div>

               </div>

               <div className="row mb-2">
                 <div className="col-md-12">

                   {index !== 0 &&
                   <button className="btn-danger border-0 p-2 rounded-0 m-0 mr-2 float-left" type="button" onClick={()=>{prevSlider(index)}}>
                     <span className="material-icons text-white">
                       arrow_back_ios
                      </span>
                   </button>
               }
               {inputData.length !== index+1 &&
                 <button className={`btn-danger border-0 p-2 rounded-0 m-0 mr-2 float-right step_${index+1}_next`} type="button" onClick={()=>{nextSlider(index)}}>
                   <span className="material-icons text-white">
                     arrow_forward_ios
                    </span>
                 </button>
               }


               </div>
             </div>

            </div>
          ))
        }

        <div className="row">
          <div className="col-md-12 d-flex justify-content-center">
          {methodType === "POST" && !editMode &&
            <button className="btn p-2 rounded-0 m-0 text-white mr-2" type="submit" style={{backgroundColor:"rgb(89 57 182)"}}>
            <span className="material-icons mr-1 text-white" style={{fontSize:"22px",cursor:"pointer"}}>save</span>
            Save Question
            </button>
          }
          {methodType === "PUT" && !editMode &&
          <div>
            <button className="btn p-2 rounded-0 m-0 text-white mr-2" type="submit" style={{backgroundColor:"rgb(89 57 182)"}}>
              <span className="material-icons mr-1 text-white" style={{fontSize:"22px",cursor:"pointer"}}>save</span>
              Update Question
            </button>

            <button className="btn p-2 rounded-0 m-0 text-white mr-2" type="button" style={{backgroundColor:"rgb(89 57 182)"}} onClick={cancelEditing}>
              <span className="material-icons mr-1 text-white" style={{fontSize:"22px",cursor:"pointer"}}>cancel</span>
              Cancel
            </button>
          </div>
        }

        { questionData._id !== null && editMode===true &&
          <div>
            <button className="btn p-2 rounded-0 m-0 text-white mr-2" type="button" style={{backgroundColor:"rgb(89 57 182)"}} onClick={cancelEditing}>
              <span className="material-icons mr-1 text-white" style={{fontSize:"22px",cursor:"pointer"}}>cancel</span>
              Cancel
            </button>
          </div>
        }

          </div>
        </div>

        </form>

        <div className={loader ? '' : 'd-none'}>
          <CustomRequestLoader/>
        </div>

    <div className="toast_modal d-none animate__animated animate__slideInRight" style={{position:"fixed",top:"100px",right:"100px",backgroundColor:"#ccc"}}>
      <div className="toast-header" style={{backgroundColor:"green",color:"white"}}>
        Range slider questionnaire
      </div>
      <div className="toast-body">
        <i className={`${icon}`}></i> {msg}
      </div>
    </div>

     </div>
   )
}

export default RangeSliderUi
