import React,{useEffect,useState} from 'react';
import useAjaxhook from "../CustomHooks/useAjaxhook";
import MaterialTable from 'material-table';
import $ from "jquery";
import CustomRequestLoader from "../customloader/CustomRequestLoader";
import ChatButton from "../Chat/ChatButton";

const ShowContent =()=>
{
     const [alldata,setAlldata] = useState([]);
     const [searchData,setSearchData] = useState([]);
     const {getalldata,updatedata} = useAjaxhook();
     const [userData,setUserData] = useState({});
     const [loader,setLoader] = useState(false);
     const columns = [
       {title:"User Name",field:"fullName"},
       {title:"Email",field:"email"},
       {title:"Mobile",field:"mobile"},
       {title:"Status",render: rowData =><input type='checkbox' checked={rowData.status} onChange={()=>{handleStatus(rowData)}} />},
     ];

      useEffect(()=>{

        if(alldata.length === 0)
        {
            const url = "https://datingapi.lipsticksncufflinks.com/member/getAllUsers";
            const ajax = getalldata(url);
            ajax.then((response)=>{
               if(response.data.status === 200)
               {
                   const data = response.data.data;
                   setAlldata(data);
                   const userd = data[0];
                   setUserData(userd);
                   setSearchData(data);
               }
            });

        }
      },[alldata,getalldata]);

      const getUserProfile=(data)=>
      {
        console.log(data)
          if(data !== "")
          {
             setUserData(data);
          }

          else
          {
             const ind = document.querySelector(".s_username").selectedIndex;
             const alloption = $(".s_username option");
             const c_index = $(alloption[ind]).attr("index");
             const userd = alldata[c_index];
             setUserData(userd);
          }

      }

      const handleStatus=(data)=>
      {
         const index = data.tableData.id;
         const status = !data.status;
         const update = [...searchData];
         update[index] = {...data,status:status};
         const id = alldata[index].userId;

         setLoader(true);
         const url = "https://datingapi.lipsticksncufflinks.com/member/updateMemberProfileByUserId/"+id;
         const ajax = updatedata(url,{status:status});
         ajax.then((response)=>{
          if(response.data.status === 200)
          {
              setSearchData(update);
              setLoader(false);
              const newData = alldata.map((obj)=>{
                return obj._id === data._id ? {...obj,status:status} : obj;
              });
              setAlldata(newData);
          }
          else
          {
            setLoader(false);
          }
         });

         ajax.catch((error)=>{
           if(error)
           {
              setLoader(false);
           }
         });

      }

      const handleSearchStatus=(event)=>
      {
         const input = event.target;
         if(input.value !== "Select status")
         {
             if(input.value === "Active")
             {
                  const newData = alldata.filter((item,index) => {
                    return item.status;
                  });

                  setSearchData(newData);
             }

             if(input.value === "Deactive")
             {
               const newData = alldata.filter((item,index) => {
                 return item.status !== true;
               });

               setSearchData(newData);
             }
         }

         else
         {
            setSearchData([...alldata]);
         }
      }


        return (
            <div className="ms-content-wrapper">
                <div className="row">
                    <div className="col-md-12">
                        <div className="ms-panel">
                            <div className="ms-panel-body">
                              <h4>Users details</h4>
                              <div className="card rounded-0">
                                <div className="card-body">
                                  <div className="top_data d-flex mt-4 justify-content-center">

                                    <div className="d-flex align-items-center justify-content-center" style={{width:"170px",height:"140px",backgroundColor:"#ccc"}}>
                                      <img src={userData.userProfile} alt="userprofile" style={{width:"100%",height:"100%"}} />
                                    </div>

                                    <div className="mx-2">

                                      <div className="d-flex align-items-center mb-1">
                                        <span style={{fontSize:"15px"}} className="font-weight-bold mr-1 p-0">User Name :</span>
                                         <span>{userData.fullName}</span>
                                      </div>

                                      <div className="d-flex align-items-center mb-1">
                                        <span style={{fontSize:"15px"}} className="font-weight-bold mr-1 p-0">Gender :</span>
                                         <span>{userData.gender}</span>
                                      </div>

                                      <div className="d-flex align-items-center mb-1">
                                        <span style={{fontSize:"15px"}} className="font-weight-bold mr-1 p-0">Age :</span>
                                         <span>{userData.age}</span>
                                      </div>

                                      <div className="d-flex align-items-center mb-1">
                                        <span style={{fontSize:"15px"}} className="font-weight-bold mr-1 p-0">Mobile :</span>
                                         <span>{userData.mobile}</span>
                                      </div>

                                      <div className="d-flex align-items-center mb-1">
                                        <span style={{fontSize:"15px"}} className="font-weight-bold mr-1 p-0">Email :</span>
                                         <span>{userData.email}</span>
                                      </div>

                                    </div>
                                  </div>

                                  <div className="my-4">
                                    <div className="d-flex justify-content-between">

                                      <select className="form-control rounded-0 mx-2 s_username" onChange={()=>{getUserProfile("")}}>
                                        <option>Select username</option>
                                        {
                                           alldata.map((udata,i)=>(
                                             <option key={udata.id} index={i}>{udata.fullName}</option>
                                           ))
                                        }
                                      </select>

                                      <select className="form-control rounded-0" onChange={handleSearchStatus}>
                                        <option>Select status</option>
                                        <option>Active</option>
                                        <option>Deactive</option>
                                      </select>
                                  </div>

                                    <div className="my-4">
                                      <MaterialTable
                                        title="users"
                                        columns={columns}
                                        data={searchData}
                                        actions={[
                                          {icon: 'visibility',tooltip: 'View user',onClick: (event, rowData) => {getUserProfile(rowData)}},
                                        ]}
                                        options={{
                                          actionsColumnIndex: -1
                                        }}
                                        />
                                    </div>

                                  </div>

                                </div>
                              </div>
                            </div>
                        </div>
                    </div>
<ChatButton/>

                </div>
                <div className={loader ? '' : 'd-none'}>
                  <CustomRequestLoader/>
                </div>
            </div>

        );

}

export default ShowContent;
