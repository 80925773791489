import React from 'react';
import Dating from "./Dating";
import Agent from "./Agent";
import Users from "./Users";
import Question from "./Question";
import Quotes from "./Quotes";
import Events from "./Events";
import ChatContent from "./ChatContent";
import ChatButton from "../Chat/ChatButton";


const Homecontent=()=>
{
   return(
     <div className="ms-content-wrapper pr-5">
         <div className="row">
           <div className="col-md-12">
               <div className="ms-panel">
                 <div className="ms-panel-body px-4 py-2">
                   <h4 className="text-center mb-5">Admin Dashboard</h4>
                   <div className="row">
                     <Users/>
                     <Dating/>
                     <ChatContent/>
                     <Question/>
                     <Quotes/>
                     <Events/>
                    </div>
                 </div>
               </div>
             </div>
         </div>

         <ChatButton/>
    </div>
   )
}

export default Homecontent;
