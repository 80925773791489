import React from 'react';
import {Link} from "react-router-dom";

const Events=()=>
{


  return(
    <div className="col-md-4">
        <div className="card rounded-0 border-0 shadow-lg py-3">
        <div className="card-body container_box pr-0">
          <div className="dashboard_icon mb-3 d-flex align-items-center justify-content-center">
              <span className="material-icons" style={{fontSize:"30px"}}>
                 calendar_today
               </span>
          </div>

          <div>
            <h6 className="text-center float-right border p-3">
              <Link to='/admin-event'>Events</Link>
            </h6>
          </div>


        </div>
      </div>
    </div>
  )
}

export default Events;
