import React from 'react'
import MaterialTable from "material-table";


 const Table= ({data, detailPanel, columns, headerStyle, rowStyle, search, actions,editable,pageSize,paging,maxBodyHeight,selection,onSelectionChange}) => {
  return (
    <div className="MTable">
      <MaterialTable style={{boxShadow:'none'}} stickyHeader
        options={{
          headerStyle: headerStyle,
          rowStyle: rowStyle, 
          pageSize:pageSize,
          paging:paging,       
          actionsColumnIndex: -1,
          search:search,
        //   maxBodyHeight:maxBodyHeight,
        //   searchFieldAlignment:"left",
        //   searchFieldStyle:{
        //   border: "2px solid #e2e2e4" ,
        //   },
          selection :selection
        }}
        title={null}
        columns={columns}
        data={data}
        //editable={editable}
        className="wrap"
        actions={actions}
        onSelectionChange={onSelectionChange}
        detailPanel={detailPanel}
      />
    </div>
  );
}

export default Table;