import React,{useState,useEffect} from 'react';
import useAjaxhook from "../CustomHooks/useAjaxhook";
import {Link} from "react-router-dom";

const Agent=()=>
{

  const {getalldata} = useAjaxhook();

  useEffect(()=>{
    getUsers();
  },[]);

  const [activeUser,setActiveUser] = useState(0);
  const [deactiveUser,setDeactiveUser] = useState(0);

  const getUsers=()=>
  {
    const url = "https://datingapi.lipsticksncufflinks.com/liveAgent/getLiveAgents";
    const ajax = getalldata(url);
    ajax.then((response)=>{
       if(response.data.status === 200)
       {
           const data = response.data.liveAgent;
           let acount = 0;
           let dcount = 0;
           data.forEach((item)=>{
             if(item.status)
             {
               acount +=1;
             }

             else
             {
               dcount +=1
             }

           });

           setActiveUser(acount);
           setDeactiveUser(dcount);
       }
    });
  }

  return(
    <div className="col-md-6 mb-5">
        <div className="card rounded-0 border-0 shadow-lg">
        <div className="card-body container_box">
          <div className="dashboard_icon mb-2 d-flex align-items-center justify-content-center">
            <span className="material-icons" style={{fontSize:"40px"}}>
               person
             </span>
          </div>
          <h6 className="text-center">
            <Link to='/agent-list'>Agents</Link>
          </h6>

         <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <div className="border p-1 d-flex align-items-center justify-content-center mr-2" style={{width:'20px !important',height:'20px !important'}}>
                <span className="material-icons text-success">
                  fiber_manual_record
                 </span>
              </div>
              <h6 className="d-flex align-items-center justify-content-center p-0 m-0">{activeUser}</h6>
            </div>

            <div className="d-flex align-items-center">
            <div className="border p-1 d-flex align-items-center justify-content-center mr-2" style={{width:'20px !important',height:'20px !important'}}>
              <span className="material-icons text-danger">
                fiber_manual_record
               </span>
            </div>
            <h6 className="d-flex align-items-center justify-content-center p-0 m-0">{deactiveUser}</h6>
          </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default Agent;
