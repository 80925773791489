// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/img/taxi.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n\n.texiContainer\n{\n    width:100%;\n    height:500px;\n    background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-size:cover;\n    position:relative;\n}\n\n.containerColor\n{\n    width:100%;\n    height:500px;\n    background-color:rgba(0, 0, 0, 0.8);\n}\n", ""]);
// Exports
module.exports = exports;
