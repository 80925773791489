import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import $ from 'jquery';
import Scrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css';
import logoWithoutTagline from '../../assets/img/logo-without-tagline.png';

import logo from '../../assets/img/lc.png';

class Sidenavigation extends Component {

    removeoverlay = () => {
        $('.ms-body').toggleClass('ms-aside-left-open');
        $('#ms-side-nav').toggleClass('ms-aside-open');
        $(".ms-aside-overlay.ms-overlay-left").toggleClass('d-block');
    }
    componentDidMount() {
        function setActiveMenuItem() {
            $('.ms-main-aside .menu-item>a').on('click', function () {
                $(this).removeAttr('href');
                var element = $(this).parent('li');
                if (element.hasClass('active')) {
                    element.removeClass('active');
                    element.find('li').removeClass('active');
                    element.find('.collapse').slideUp();
                } else {
                    element.addClass('active');
                    element.children('.collapse').slideDown();
                    element.siblings('li').children('.collapse').slideUp();
                    element.siblings('li').removeClass('active');
                    element.siblings('li').find('li').removeClass('active');
                    element.siblings('li').find('.collapse').slideUp();
                }
            });
        }
        setActiveMenuItem();
    }
    render() {
        return (
            <div>
                <div className="ms-aside-overlay ms-overlay-left ms-toggler" onClick={this.removeoverlay}></div>
                <div className="ms-aside-overlay ms-overlay-right ms-toggler"></div>
                <Scrollbar id="ms-side-nav" className="side-nav fixed ms-aside-scrollable ms-aside-left">
                    {/* Logo */}
                    <div className="logo-sn ms-d-block-lg">
                        <Link className="pl-0 ml-0 text-center" to="/">
                            <img src={logo} alt="logo"/>
                        </Link>
                    </div>
                    {/* Navigation */}
                    <ul className="accordion ms-main-aside fs-14" id="side-nav-accordion">

                        {/* product */}

                        <li className="menu-item">
                            <Link to="/dashboard">
                              <span><i className="fas fa-chart-pie fs-16" />Dashboard</span>
                            </Link>
                        </li>

                        <li className="menu-item">
                            <Link to="/admin-event">
                              <span><i className="fas fa-calendar fs-16" />Events</span>
                            </Link>
                        </li>

                        <li className="menu-item">
                            <Link to="/basic-users-details">
                              <span><i className="fa fa-user fs-16" />User management</span>
                            </Link>
                        </li>

                        {/*
                        <li className="menu-item">
                            <Link to="/agent-list">
                              <span><i className="fas fa-user-tie fs-16" />Agent management</span>
                            </Link>
                        </li>
                        */}

                        <li className="menu-item">
                            <Link to="/add-text-content"> <span><i className="fas fa-file-alt fs-16" />Manage text content</span>
                            </Link>
                        </li>

                        <li className="menu-item">
                            <Link to="/show-video-content"> <span><i className="fa fa-video fs-16" />Manage splash video</span>
                            </Link>
                        </li>

                        <li className="menu-item">
                            <Link to="/add-quotes-content">
                              <span><i className="fa fa-quote-left  fs-16" />Quotes</span>
                            </Link>
                        </li>

                        <li className="menu-item">
                            <Link to="/add-tips-content">
                              <span><i className="fas fa-heart fs-16" />Dating tips</span>
                            </Link>
                        </li>

                        <li className="menu-item">
                            <Link to="#" className="has-chevron"> <span><i className="fa fa-question fs-16" />Compatibility questions</span>
                            </Link>
                            <ul id="product" className="collapse" aria-labelledby="product" data-parent="#side-nav-accordion">
                                <li>
                                  <Link to="/add-new-question" >
                                    <i className="fas fa-plus-square fs-16"></i>Add question
                                  </Link>
                                </li>

                                <li>
                                  <Link to="/questions-list" >
                                    <i className="fas fa-plus-square fs-16"></i>questions list
                                  </Link>
                                </li>

                            </ul>
                        </li>

                        <li className="menu-item">
                            <Link to="/admin-memberprofile-ques">
                              <span><i className="fa fa-question fs-16" />Member Question</span>
                            </Link>
                        </li>


                        <li className="menu-item">
                            <Link to="#" className="has-chevron"> <span><i className="fa fa-book fs-16" />Investment</span>
                            </Link>
                            <ul id="product" className="collapse" aria-labelledby="product" data-parent="#side-nav-accordion">
                                <li>
                                  <Link to="/admin-investment" >
                                    <i className="fas fa-plus-square fs-16"></i>Create Plan
                                  </Link>
                                </li>

                                <li>
                                  <Link to="/admin-investment-list" >
                                    <i className="fas fa-plus-square fs-16"></i>Plan List
                                  </Link>
                                </li>

                            </ul>
                        </li>

                        <li className="menu-item">
                            <Link to="/services">
                              <span><i className="fas fa-user-cog fs-16" />Services</span>
                            </Link>
                        </li>

                        <li className="menu-item">
                            <Link to="/servicesMaster">
                              <span><i className="fas fa-user-edit fs-16" />Services Master</span>
                            </Link>
                        </li>


                    </ul>
                </Scrollbar>
            </div>
        );
    }
}

export default Sidenavigation;
