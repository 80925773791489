import firebase from "firebase";

const config = {
  apiKey: "AIzaSyAqgmqINmQoC9-SdO8BW6PcIB9vTawHjtA",
  authDomain: "datingapp-107f5.firebaseapp.com",
  projectId: "datingapp-107f5",
  storageBucket: "datingapp-107f5.appspot.com",
  messagingSenderId: "831096692921",
  appId: "1:831096692921:web:bc6db5d483269c1e7e7b68",
  measurementId: "G-NK06DJ9955"
};

firebase.initializeApp(config);

export default firebase;
