import React,{useState,useEffect} from 'react';
import axios from "axios";
import Swal from 'sweetalert2';
import {Button,Spinner} from "react-bootstrap";

const InvestmentFormContent=()=>
{

  const [loader,setLoader] = useState(false);
  const [planData,setPlanData] = useState([]);

  const [inputData,setInputData] = useState({
    subscriptionName:"",
    price: "",
    subscriptionType: "Free",
    subscriptionDays: ""
  });

  const [featuresPoints,setfeaturesPoints] = useState([
    {
      featureName:""
    }
  ]);

  const handleInput=(event)=>
  {
     const name = event.target.name;
     const val  = event.target.value;
     return setInputData((oldData)=>{
       return {
         ...oldData,
         [name]:val
       }
     });
  }

  const addPoints=()=>
  {
     const oldData = [...featuresPoints];
     oldData.push({featureName:""});
     setfeaturesPoints(oldData);
  }

  const removePoints=(index)=>
  {
     const newData = featuresPoints.filter((item,i) => {
       return i !== index;
     });

     return setfeaturesPoints(newData);
  }

  const handleFeaturePoints=(event,index)=>
  {
     const val = event.target.value;
     const data = [...featuresPoints];
     data[index] = {featureName:val};
     setfeaturesPoints(data);
  }

  const handleSubmit=(event)=>
  {
      event.preventDefault();
      setLoader(true);
      const ajax = axios({
        method:"POST",
        url:"/createSubscription",
        data:{
          ...inputData,
          featuresPoints:featuresPoints,
          status:true
        }
      });

      ajax.then((response)=>{
        Swal.fire(response.data.message);
        if(response.status ===200)
        {
           setInputData({
             subscriptionName:"",
             price: "",
             subscriptionType: "Free",
             subscriptionDays: ""
           });

           setfeaturesPoints(
             [
               {
                 featureName:""
               }
             ]
           );
        }
      });

      ajax.catch((error)=>{
        console.log(error);
      });

      ajax.finally(()=>{
        setLoader(false);
      });
  }

  useEffect(()=>{

    const ajax = axios({
      method:"GET",
      url:"/getAllSubscriptionsTypes"
    });

    ajax.then((response)=>{
      if(response.status===200)
      {
         let data = response.data.data;
         setPlanData(data);
      }

    });

  },[]);

   const ui = (
     <>
     <div className="ms-content-wrapper">
         <div className="row">
           <div className="col-md-12">
               <div className="ms-panel p-4 ">
                 <h4 className="text-center mt-2 mb-4">Create Plan</h4>
                  <div>
                   <form onSubmit={handleSubmit}>

                     <div className="row">

                       <div className="col-md-6">

                         <div className="form-group">
                           <label>Subscription name</label>
                           <input type="text"
                             name="subscriptionName"
                             className="form-control rounded-0"
                             onChange={handleInput}
                             value={inputData.subscriptionName}
                             required
                             />
                         </div>

                         <div className="form-group">
                           <label>Subscription Days</label>
                           <input type="text"
                             name="subscriptionDays"
                             className="form-control rounded-0"
                             onChange={handleInput}
                             value={inputData.subscriptionDays}
                             required
                             />
                         </div>

                       </div>

                       <div className="col-md-6">

                         <div className="form-group">
                           <label>Subscription Type</label>
                           <select className="form-control" name="subscriptionType" onChange={handleInput} value={inputData.subscriptionType}>
                             <option>Select Subscription Type</option>
                             {
                               planData.map((item,index)=>{
                                 return (
                                   <>
                                    <option value={item} key={index}>{item}</option>
                                   </>
                                 )
                               })
                             }
                           </select>
                         </div>

                         <div className="form-group">
                           <label>Price</label>
                           <input type="text"
                             name="price"
                             className="form-control rounded-0"
                             onChange={handleInput}
                             value={inputData.price}
                             required
                             />
                         </div>

                       </div>

                       {
                         featuresPoints.map((data,index)=>{
                         return  (
                           <>
                           <div className="col-md-6 mb-2" key={index}>
                             <input
                               placeholder="Enter Features"
                               className="form-control rounded-0"
                               type="text"
                               value={data.featureName}
                               onChange={(event)=>{handleFeaturePoints(event,index)}}
                               required
                               />
                           </div>

                           <div className="col-md-6">
                             <button type="button" className="btn-danger" onClick={()=>{removePoints(index)}}>
                               <i className="fa fa-minus"></i>
                             </button>
                           </div>
                           </>
                         )
                         })
                       }



                       <div className="col-md-12">
                         <center>
                           <button type="button" className="btn btn-danger rounded-0" onClick={addPoints} style={{marginRight:"10px"}}>
                             Add More Feature
                           </button>

                           {
                             loader ?   <Button className="btn btn-danger rounded-0" disabled>
                                        <Spinner
                                          as="span"
                                          animation="border"
                                          size="sm"
                                          role="status"
                                          aria-hidden="true"
                                        />
                                        <span className="visually-hidden">Loading...</span>
                                       </Button> :  <button type="submit" className="btn btn-danger rounded-0">
                                                        Create Plan
                                                      </button>
                           }


                         </center>
                       </div>

                     </div>


                   </form>
                 </div>
               </div>
             </div>
         </div>
       </div>
     </>
   );
   return ui;
}

export default InvestmentFormContent;
