import React,{useState,useEffect} from 'react';
import FullCalendar, { formatDate } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import "./main.css";
import Modal from 'react-bootstrap/Modal'
import TimeZones from "./TimeZone";
import axios from "axios";
import "./Content.css";
import $ from "jquery";
import { useSelector } from 'react-redux';
import CustomRequestLoader from "../customloader/CustomRequestLoader";
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import Moment from 'react-moment';

const ContentTest=()=>
{

  const user = useSelector(state => state.user);
  const [inputStatus,setInputStatus] = useState(true);
  const [editIcon,seteditIcon] = useState(false);
  const [loader,setLoader] = useState(false);
  const [weekendsVisible,setweekendsVisible] = useState(true);
  const [currentLoginId,setCurrentLoginId] = useState(null);
  const [currentEvents,setcurrentEvents] = useState([]);
  const [data,setData] = useState([]);
  const [show, setShow] = useState(false);
  const [btn,setBtn] = useState("Add event");
  const [inputData,setInputData] = useState(
    {
      userId: "",
      title: "",
      description: "",
      startDate: new Date(),
      endDate: new Date(),
      remainder: "5",
      startTime: "",
      endTime: "",
      mobileEventId: "",
      timeZone: "Europe/Andorra",
      location: {
      where: ""
      }
    }
  );
  const [remainData,setRemainData] = useState([]);

useEffect(()=>{
    const userId = user.user_details.user_id;
    setCurrentLoginId(userId);
    setInputData({...inputData,userId:userId});
  },[]);

 useEffect(()=>{

   if(currentLoginId !== null)
   {
       const ajax = axios({
         method:"GET",
         url:`/getEventsByUserId/${currentLoginId}`,
       });

       ajax.then((response)=>{
         const tempData = response.data.data;
         const titleData = [];
         if(response.data.status === 200)
         {
           for(let data of response.data.data)
           {
               titleData.push({
                 start:new Date(data.startDate).toISOString().replace(/T.*$/, ''),
                 end:new Date(data.endDate).toISOString().replace(/T.*$/, ''),
                 title:data.title
               });
           }
           setData(titleData);
           setRemainData(tempData);
         }
       });
   }

 },[currentLoginId]);


 const handleClose = () => setShow(false);
 const handleShow = () => setShow(true);

  const handleDateSelect = (selectInfo) =>
  {
     seteditIcon(false);
     setBtn("Add event");
     setInputStatus(false);
     handleShow();
     setInputData({
          ...inputData,
           startDate:new Date(selectInfo.startStr),
           endDate:new Date(selectInfo.endStr),
           title: "",
           description: "",
           mobileEventId:"",
           timeZone: "Europe/Andorra",
           startTime:"",
           endTime:"",
           location:"",
           type:"POST",
           _id:""
         });
  }

  const handleEventClick = (clickInfo) =>
  {
     setInputStatus(true);
     seteditIcon(true);
     setBtn("Update event");
     const defid = clickInfo.event._def.defId;
     let index = "";
     for(let i=0;i<currentEvents.length;i++)
     {
       let data = currentEvents[i];
       if(data._def.defId === defid)
       {
          index = i;
          break;
       }
     }

     const selectedObj = remainData[index];
     let sdate = new Date(selectedObj.startDate);
     let edate = new Date(selectedObj.endDate);

     setInputData({...selectedObj,startDate:sdate,endDate:edate});
     handleShow();

  }

  const createEvent=(event)=>
  {
      event.preventDefault();
      let method = "";
      let url = "";
      if(inputData.type === "POST")
      {
         method = "POST";
         url = "/createEvent"
      }

      if(inputData.type === "PUT")
      {
         method = "PUT";
         url = "/updateEventById/"+inputData._id;
      }
      setLoader(true);
      const ajax = axios({
        method:method,
        url:url,
        data:{
          ...inputData
        }
      });

      ajax.then((response)=>{
        setLoader(false);
        if(response.status === 200)
        {
           handleClose();
           const newData = {
             start:new Date(response.data.data.startDate).toISOString().replace(/T.*$/, ''),
             end:new Date(response.data.data.endDate).toISOString().replace(/T.*$/, ''),
             title:response.data.data.title,
           }
           setData([...data,newData]);
           let index = 0;
           if(method === "PUT")
           {
              const currentId = inputData._id;
              remainData.forEach((item, i) =>
              {
                 if(item._id === currentId)
                 {
                    index = i;
                 }
              });

              const updateData = [...data];
              updateData[index] = newData;
              setData(updateData);

              const updateRemainData = [...remainData];
              updateRemainData[index] = response.data.data;
              setRemainData(updateRemainData);
           }
           else
           {
              setRemainData([...remainData,response.data.data]);
           }

        }
      });

      ajax.catch((error)=>{
        setLoader(false);
      });
  }


  const editEvents=()=>
  {
     setInputStatus(false);
     setInputData({...inputData,type:"PUT"});
  }

  const cancelEvent=()=>
  {
     let confirm = window.confirm("Do you want to cancel editing");
     if(confirm)
     {
        handleClose();
     }
  }

  const handleEvents = (events) =>
  {
     setcurrentEvents(events);
  }

  const deleteEvent=()=>
  {
     const confirm = window.confirm("Are you sure do you want to delete it?");
     if(confirm)
     {

       $(".deleteEventBtn").html("<i class='fa fa-spinner fa-spin'></i>");
       setLoader(true);
       const ajax = axios({
         method:"DELETE",
         url:`/deleteEventById/${inputData._id}`
       });

       ajax.then((response)=>{
         setLoader(false);
         if(response.status === 200)
         {
             $(".deleteEventBtn").html("<i class='fa fa-trash'></i>");
             handleClose();

             const tempData = [];
             let ind = 0;
             const newData = remainData.filter((item,i)=>{
               if(item._id === inputData._id)
               {
                  ind = i;
                  return false;
               }
               else
               {
                  return true;
               }
              });

              const fdata = data.filter((item,i)=>{
                return i !== ind;
              });

              setRemainData(newData);
              setData(fdata);
         }
       });

       ajax.catch((error)=>{
         setLoader(false);
         $(".deleteEventBtn").html("<i class='fa fa-trash'></i>");
       });
     }

  }

  const handleInput=(event)=>
  {
     const name = event.target.name;
     const val  = event.target.value;
     setInputData({...inputData,[name]:val});
  }

  const handleStartDate=(data)=>
  {
     setInputData({...inputData,startDate:data});
  }

  const handleEndDate=(data)=>
  {
     setInputData({...inputData,endDate:data});
  }



  const renderEventContent=(eventInfo)=>
  {
    return (
      <>
        <b>{eventInfo.timeText}</b>
        <i>{eventInfo.event.title}</i>
      </>
    )
  }


  return (
    <div className="ms-content-wrapper">
        <div className="row">
            <div className="col-md-12">
                <div className="ms-panel">
                    <div className="ms-panel-body">
                      <div className='demo-app'>

                        <div className='demo-app-main'>
                          <FullCalendar
                            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                            headerToolbar={{
                              left: 'prev,next today',
                              center: 'title',
                              right: 'dayGridMonth,timeGridWeek,timeGridDay'
                            }}
                            initialView='dayGridMonth'
                            editable={true}
                            selectable={true}
                            selectMirror={true}
                            dayMaxEvents={true}
                            weekends={weekendsVisible}
                            events={data} // alternatively, use the `events` setting to fetch from a feed
                            select={handleDateSelect}
                            eventContent={renderEventContent} // custom render function
                            eventClick={handleEventClick}
                            eventsSet={handleEvents} // called after events are initialized/added/changed/removed
                            /* you can update a remote database when these fire:
                            eventAdd={function(){}}
                            eventChange={function(){}}
                            eventRemove={function(){}}
                            */
                          />
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>


<Modal show={show} onHide={handleClose}>

      <Modal.Header>
        {editIcon &&
        <div className="ml-auto">
          <button className="icon-btn-info mr-2" onClick={editEvents}>
            <i className="fa fa-edit"></i>
          </button>
          <button className="icon-btn-danger deleteEventBtn" onClick={deleteEvent}>
            <i className="fa fa-trash"></i>
          </button>
        </div>
        }
        <button className="icon-btn-danger mx-2" onClick={handleClose}>
          <i className="fa fa-times-circle"></i>
        </button>
      </Modal.Header>


      <Modal.Body>
        <form onSubmit={createEvent}>

          <div className="form-group">
            <label>Title</label>
            <input type="text" className="form-control rounded-0" name="title" value={inputData.title} onChange={handleInput} disabled={inputStatus} required="required"/>
          </div>

          <div className="form-group">
            <label>Description</label>
            <input type="text" className="form-control rounded-0" name="description" value={inputData.description} disabled={inputStatus} onChange={handleInput} required="required"/>
          </div>

          <div className="form-group">
            <label>Timezone</label>
            <select className="form-control rounded-0" name="timeZone" value={inputData.timeZone} disabled={inputStatus} onChange={handleInput}>
            {
                TimeZones.map((timeData,index)=>(
                <option key={index}>{timeData}</option>
                ))
            }
            </select>
          </div>

        <div className="d-flex">
          <div className="form-group mr-2 w-50">
            <label>Start date</label>
            <Datetime disabled={inputStatus} value={inputData.startDate} name="startDate" onChange={(date)=>{handleStartDate(date)}} inputProps={{disabled:inputStatus}} />
          </div>

          <div className="form-group w-50">
            <label>End date</label>
            <Datetime disabled={inputStatus} value={inputData.endDate} name="endDate" onChange={(date)=>{handleEndDate(date)}} inputProps={{disabled:inputStatus}} />
          </div>

        </div>

          <div className="form-group">
            <label>Where</label>
            <input type="text" className="form-control rounded-0" name="location" value={inputData.location} onChange={handleInput} disabled={inputStatus} required="required"/>
          </div>

          <div className="form-group m-0">
            {inputStatus !== true &&
            <button className="btn btn-danger rounded-0 mr-2" type="submit">{btn}</button>
            }
            {editIcon&&
            <button className="btn btn-danger rounded-0" type="submit" onClick={cancelEvent}>
              Cancel
            </button>
            }
          </div>

        </form>
      </Modal.Body>
</Modal>

<div>
  {loader && <CustomRequestLoader/>}
</div>

    </div>
  )
}

export default ContentTest;
