import React,{useContext, useEffect, useRef, useState} from 'react';
import Conversation from "./Conversation/Conversation";
import Message from "./Message/Message";
import ChatOnline from "./ChatOnline/ChatOnline";
import "./Chat.css";
import axios from "axios";
import { useDispatch, useSelector } from 'react-redux';
import { io } from "socket.io-client";


const Content=()=>
{

  const [reqUser,setReqUser] = useState([]);
  const [reqUserFilterData,setReqUserFilterData] = useState([]);
  const user = useSelector(state => state.user);
  const [currentLoginId,setCurrentLoginId] = useState("");
  const [currentChat,setCurrentChat] = useState([]);
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const [currentSelectUser,setCurrentSelectUser] = useState(null);
  const [newMessage,setNewMessage] = useState("");
  const socket = useRef();
  const scrollRef = useRef();
  const [lastMessageList , setLastMessageList] = useState([]);


useEffect(() => {
     socket.current = io("https://datingws.lipsticksncufflinks.com");
     socket.current.on("getMessage", (data) => {
      //  console.log(data);
       setArrivalMessage({
        _id:data._id,
         senderUserId:data.senderUserId,
         message:data.message,
         receiverUserId:data.receiverUserId,
         createdAt: Date.now(),
       });
     });
     return () => { socket.current.disconnect() }
   }, []);

useEffect(() => {
   // arrivalMessage &&
   //   currentChat?.members.includes(arrivalMessage.sender) &&
   //   setMessages((prev) => [...prev, arrivalMessage]);
    let find = false;
    if(arrivalMessage !== null && currentSelectUser !== null)
    {
       if(currentSelectUser.userId === arrivalMessage.senderUserId)
       {
        socket.current.emit("messageRead",arrivalMessage._id);
        console.log(arrivalMessage,"condition true");
          find = true;
          setCurrentChat([...currentChat,{...arrivalMessage}]);
          setLastMessageList([{...arrivalMessage}])
          console.log(currentChat,"current chat");
       }

       else
       {
        // console.log("else condition");
           reqUser.forEach((item, i) =>
           {
              if(item.userId === arrivalMessage.senderUserId)
              {
                find = true;
                let newData = {
                  ...arrivalMessage,
                  userId:arrivalMessage.senderUserId,
                  profileImage:item.profileImage,
                  fullName:item.fullName,
                  count:item.count+1
                }

                const updateData = [...reqUser];
                const newArray = updateData.filter((item,ind) =>
                {
                    return i !== ind;
                });
                newData.newMsg = true;
                setReqUser([newData,...newArray]);
                setReqUserFilterData([newData,...newArray]);

              }
           });

       }
    }

    if(arrivalMessage !== null && currentSelectUser === null)
    {
      reqUser.forEach((item, i) =>
      {
         if(item.userId === arrivalMessage.senderUserId)
         {
           find = true;
           let newData = {
             ...arrivalMessage,
             userId:arrivalMessage.senderUserId,
             profileImage:item.profileImage,
             fullName:item.fullName,
             count:item.count+1,
             newMsg:true
           }

           const updateData = [...reqUser];
           const newArray = updateData.filter((item,ind) =>
           {
               return i !== ind;
           });
           setReqUser([newData,...newArray]);
           setReqUserFilterData([newData,...newArray]);

         }
      });
    }

    // if(find !== true)
    // {
       getAllUser();
    // }
    // console.log("userId",currentLoginId);
    // socket.current.emit("getUserChatList",currentLoginId);
    // socket.current.on("getUpdatedUserChatList",(data) => {
    //   console.log("getUpdatedUserChatList",data);
    // })
 }, [arrivalMessage]);

useEffect(() => {
  socket.current.emit("addUser",currentLoginId);
  socket.current.on("getUsers", (users) => {
    // console.log(users)
  });
}, [currentLoginId]);

const handleSubmit = (e) => {
  
  e.preventDefault();
  if(newMessage && newMessage != ""){

    socket.current.emit("sendMessage", {
    senderUserId:currentLoginId,
    receiverUserId:currentSelectUser.userId,
    message:newMessage,
    isAgentMessage:true
    });
  
    setCurrentChat([...currentChat,{message:newMessage,senderUserId:currentLoginId,createdAt:Date.now()}]);
    setNewMessage("");

    //   socket.current.emit("getUserChatList",currentLoginId);
    //   console.log("currentLoginId",currentLoginId);
    // socket.current.on("getUpdatedUserChatList",(data) => {
    //   console.log("getUpdatedUserChatList",data);
    // })
    getAllUser();
  }else{
    console.log("message required");
  }

}

useEffect(() => {
  if(currentChat.length>0)
  {
     if(scrollRef.current)
     {
       scrollRef.current.scrollIntoView({ behavior: "smooth" });
     }
  }
}, [currentChat])


  useEffect(()=>{
    getAllUser();
  },[]);


  const getAllUser=()=>
  {

    const userId = user.user_details.user_id;
    setCurrentLoginId(userId);
    const ajax = axios({
      method:"GET",
      url:"/getChatUsersByUserIdForAdmin/"+userId,
    });
    ajax.then((response)=>{
      console.log(response,"get all user");
      if(response.data.status !== 400)
      {
        const tempData = [];
        const res_data = response.data.data;
        console.log(response);
        res_data.forEach((item, i) =>
        {
           if(item.senderUserId === userId && item.isReceiverRead === true)
           {
              tempData.push({...item,newMsg:false,count:0});
           }
           else
           {
            if(item.isReceiverRead === true){
              tempData.push({...item,newMsg:false,count:0});
            }
            else{
              if(item.senderUserId !== userId){

                tempData.push({...item,newMsg:true,count:0});
              }else{
              tempData.push({...item,newMsg:false,count:0});

              }
            }
           }
        });
        // console.log(tempData,"temp data");
        setReqUser(tempData);
        setReqUserFilterData(tempData);
      }
    });
  }


  const acceptChatWithUser=(data,index)=>
  {
      const userId  = data.userId;
      const agentId = currentLoginId;
      const request = {userId:userId,agentId:agentId};
      const ajax = axios({
        method:"PUT",
        url:"/updateReadStatusBySenderIdReceiverId",
        data:{
          senderUserId:userId,
          receiverUserId:agentId
        }
      });
      ajax.then((response)=>{
        getSenderRecieverData(request);
        setCurrentSelectUser(data);
        const updateData = [...reqUser];
        updateData[index] = {...data,newMsg:false,test:"adada"};
        setReqUser(updateData);
        setReqUserFilterData(updateData);
      });
      ajax.catch((error)=>{
        console.log(error);
      });
  }


  const getSenderRecieverData=(request)=>
  {

     const ajax = axios({
       method:"POST",
       url:"/getChatBySenderIdRecieverId",
       data:{
        senderUserId:request.userId,
        receiverUserId:request.agentId
       }
     });
     ajax.then((response)=>{
       if(response.data.status === 200)
       {
          const data = response.data.data;
          setCurrentChat(data);
       }
     });
  }

  const endChatWithUser=()=>
  {
     const confirm = window.confirm("Do yo want to end chat ?");
     if(confirm)
     {
        const ajax = axios({
          method:"PUT",
          url:"/endChatByAgent",
          data:{
            userId:currentSelectUser.userId
          }
        });

        ajax.then((response)=>{
          setCurrentSelectUser(null);
          setCurrentChat([]);
        });
     }
  }

  const handleInput=(event)=>
  {
     const val = event.target.value;
     setNewMessage(val);
  }


  const filterUser=(event)=>
  {
     const keyword = event.target.value.toUpperCase();
     const newData = reqUserFilterData.filter((item,i) =>
     {
         if(item.fullName.toUpperCase().indexOf(keyword) !== -1)
         {
            return true;
         }
         else
         {
            return false;
         }
     });

     setReqUser(newData);
  }


  return (
      <div className="ms-content-wrapper p-2 px-4">
          <div className="row">
              <div className="col-md-12">
                  <div className="ms-panel p-2">
                    <div className="messenger">

                        <div className="chatMenu">
                          <div className="chatMenuWrapper">
                             <input className="chatMenuInput" placeholder="Search for freinds" onChange={filterUser}/>
                             <div style={{overflowY:"auto",overflowX:"hidden",height:"70vh"}}>
                               {
                                  reqUser.map((data,index)=>(
                                      <div key={data._id}>
                                        <Conversation
                                          userData={reqUser}
                                          uindex={index}
                                          acceptChatWithUser={acceptChatWithUser}
                                          />
                                      </div>
                                  ))
                               }
                             </div>
                          </div>
                        </div>


                        <div className="chatBox">
                          <div style={{backgroundColor:"#F5F8FB",height:"100%"}}>
                              {currentSelectUser !== null ?
                                <>

                                <div className="d-flex align-items-center p-1" style={{backgroundColor:"#422b84"}}>
                                  <img src={`${currentSelectUser.profileImage}`} alt="user" className="conversation_img mr-3" />
                                  <p className="p-0 m-0 text-white">{currentSelectUser.fullName}</p>
                                </div>
                              <div className="chatBoxWrapper">

                              <div className="chatBoxTop">
                                {
                                  currentChat.map((data,index)=>(
                                    <div ref={scrollRef} >
                                    <Message key={index} chatData={data} chatIndex={index} own={data.senderUserId === currentLoginId}  />
                                    </div>
                                  ))
                                }
                              </div>

                              <div className="chatBoxBottom">
                                <form onSubmit={handleSubmit}>
                                  <input className="form-control " placeholder="Type something press enter" style={{height:"40px"}} onChange={handleInput} value={newMessage} />
                                </form>
                              </div>

                            </div>
                            </>
                            :<span className="noConversationText"></span>
                            }
                          </div>
                        </div>

                    </div>
                  </div>
              </div>
          </div>
      </div>

  );
}

export default Content;
