import React,{useState} from 'react';
import "./Booking.css";
import "animate.css"

const image = "../../../assets/img/taxi.jpg";

const Content=()=>
{

  const [buyBtn,setBuyBtn]   = useState(true);
  const [buyForm,setBuyForm] = useState(false);

  const handleForm=()=>
  {
     setBuyBtn(!buyBtn);
     setBuyForm(!buyForm);
  }

  return (
      <div className="ms-content-wrapper">
          <div className="row">
              <div className="col-md-12">
                  <div className="ms-panel">
                      <div className="ms-panel-header">
                          <h6 className="text-center">Online booking</h6>
                      </div>
                      <div className="ms-panel-body p-0 overflow-hidden">
                        <div className="texiContainer">
                          <div className="containerColor d-flex justify-content-center align-items-center">
                            {buyBtn &&
                            <button className="btn btn-white rounded-0 animate__animated animate__fadeIn" onClick={handleForm}>
                              Book Now
                            </button>
                           }
                           {buyForm &&
                           <div className="p-4 animate__animated animate__slideInDown w-75">
                              <form>

                                <div className="form-group">
                                  <label className="text-white">Person name</label>
                                  <input type="text" name="person_name" className="form-control rounded-0"/>
                                </div>

                                <div className="form-group">
                                  <label className="text-white">Person mobile</label>
                                  <input type="text" name="person_mobile" className="form-control rounded-0"/>
                                </div>

                                <div className="form-group">
                                  <label className="text-white">From</label>
                                  <input type="text" name="from" className="form-control rounded-0"/>
                                </div>

                                <div className="form-group">
                                  <label className="text-white">To</label>
                                  <input type="text" name="to" className="form-control rounded-0"/>
                                </div>

                                <div className="form-group">
                                  <label className="text-white">Date</label>
                                  <input type="date" name="date" className="form-control rounded-0"/>
                                </div>

                                <div className="form-group d-flex justify-content-end">
                                  <button className="btn btn-white text-dark rounded-0 ml-auto">Book Now</button>
                                </div>

                              </form>
                            </div>
                          }
                          </div>
                        </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>

  );
}
export default Content;
