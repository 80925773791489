import React, { useState, useEffect } from 'react';
import costiclogo from '../../../assets/img/lc.png';
import logoWithoutTagline from '../../../assets/img/logo-without-tagline.png';
import axios from "axios";
import { Modal } from "react-bootstrap";
import "animate.css"
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
const Content = (props) => {
  const [show, setShow] = useState(false);
  const [resetMsg, setResetMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [icon, setIcon] = useState("");

  const [token, setToken] = useState("");
  const [inputData, setInputData] = useState({
    password: "",
    confirmPass: "",
    showPassword: false,
    showConfirm: false
  });


  useEffect(() => {
    const queryString = require('query-string');
    const parsed = queryString.parse(props.data.location.search);
    const token = parsed.user;
    setToken(token);
  }, [props.data.location.search]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleInput = (event) => {
    const name = event.target.name;
    const val = event.target.value;
    setInputData({ ...inputData, [name]: val });
  }

  const changePassword = (event) => {
    event.preventDefault();

    if (inputData.password === inputData.confirmPass) {
      var pass = inputData.confirmPass;
      var reg = new RegExp('^(?=.*?[A-Za-z])(?=.*?[0-9]).{6,}$');
      var test = reg.test(pass);
      if (test) {
        const ajax = axios({
          method: "POST",
          url: "https://datingapi.lipsticksncufflinks.com/users/resetPassword",
          data: {
            token: token,
            newPassword: inputData.confirmPass
          }
        });

        ajax.then((response) => {
          if (response.data.status === 200) {
            handleShow();
            setInputData({ password: "", confirmPass: "" });
            setIcon("fa fa-check-circle");
            setResetMsg("Your password has been successfully reset login with your new credentials");
          }
          else if (response.data.status === 400) {
            handleShow();
            setIcon("fa fa-times-circle");
            setResetMsg("Link has been expired");
          }
        });

        ajax.catch((error) => {
          console.log(error);
        });
      }

      else {
        setErrorMsg("Use strong password !");
        setTimeout(() => {
          setErrorMsg("");
        }, 5000);
      }

    }
    else {
      setErrorMsg("New password and confirm password should be same");
      setTimeout(() => {
        setErrorMsg("");
      }, 5000);
    }
  }

  const handleClickShowPassword = () => {
    setInputData({ ...inputData, showPassword: !inputData.showPassword });
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  const handleClickShowConfirmPassword = () => {
    setInputData({ ...inputData, showConfirm: !inputData.showConfirm });
  }

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className="ms-content-wrapper ms-auth">
      <div className="ms-auth-container">
        <div className="ms-auth-col">
          <div className="ms-auth-form">
            <form onSubmit={changePassword}>
              <div className="d-flex justify-content-center">
                <img src={logoWithoutTagline} alt="logo" />
              </div>

              <h4 className="text-center">Reset password</h4>
              <div className="mb-3">
                <label htmlFor="validationCustom08">New Password</label>
                <div className="input-group">
                  <Input type={inputData.showPassword ? "text" : "password"} disableUnderline={true} className="form-control " name="password" value={inputData.password} onChange={handleInput} placeholder="New password" required
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {inputData.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                   
                    />
                </div>
              </div>
              <div className="mb-2">
                <label htmlFor="validationCustom09">Confirm Password</label>
                <div className="input-group">
                  <Input type={inputData.showConfirm ? "text" : "password"} disableUnderline={true}  className="form-control" name="confirmPass" value={inputData.confirmPass} onChange={handleInput} placeholder="Password" required endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownConfirmPassword}
                      >
                        {inputData.showConfirm ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  } />
                </div>
              </div>

              <div className="mb-2">
                {
                  errorMsg !== "" && <p className="text-center text-danger animate__animated animate__zoomIn">{errorMsg}</p>
                }

              </div>

              <button className="btn btn-danger mt-4 d-block m-auto" type="submit">
                Change password
              </button>

            </form>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} id="error" className="modal-min" aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Body className="text-center p-0">
          <div className="d-flex justify-content-center align-items-center" style={{ backgroundColor: "#79B82B", height: "120px" }}>
            <div>
              <i className={`${icon}`} style={{ color: "white !important" }}></i>
              <p className="text-white font-weight-bold my-2" style={{ fontSize: "20px" }}>Password Reset</p>
            </div>
          </div>

          <div className="d-flex justify-content-center align-items-center" style={{ height: "120px" }}>
            <p style={{ wordSpacing: "3px", letterSpacing: "1px" }} className="px-4">
              {resetMsg}
            </p>
          </div>

        </Modal.Body>
      </Modal>

    </div>
  )
}

export default Content;
