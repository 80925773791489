import React,{useState,useEffect} from 'react';
import CustomRequestLoader from "../customloader/CustomRequestLoader";
import useAjaxHook from "../CustomHooks/useAjaxhook";
import axios from "axios";
import $ from "jquery";

import RangeSliderUi from "./RangeSliderUi";
import ToggleQuestion from "./ToggleQuestion";
import RankChoose from "./RankChoose";
import PragraphContent from "./PragraphContent";
import SingleChoiceContent from "./SingleChoiceContent";
import MultipleChoiceContent from "./MultipleChoiceContent";


const QuestionsContent=(props)=>
{
      const option = ["range-slider","toggle choice","rank choice","single choice", "multiple choice", "paragraph"];

       const [dynamicUi,setDynamicUi] = useState("");
       const [selectChoice,setselectChoice] = useState("Select questionnaire type");
       const [questionData,setquestionData] = useState("");
       const [editMode,setEditMode] = useState(false);

       useEffect(()=>{
         if(props.data.location.search !== "")
         {

            const data = props.data.location.search;
            let id = "";
            if(data.indexOf("view") !== -1)
            {
              id = data.split("=")[1];
              setEditMode(true);
            }
            else
            {
                id = data.split("=")[1];
                setEditMode(false);
            }

            const ajax = axios({
              method:"GET",
              url:"/compQuestion/"+id
            });

            ajax.then((response)=>{
              if(response.data.status === 200)
              {
                 const qus = response.data.compQuestion;
                 setDynamicUi(qus.questionType);
                 setselectChoice(qus.questionType);
                 setquestionData(qus);
              }
            });

         }
       },[props.data.location.search]);

       const handleUi=(event)=>
       {
            const val = event.target.value;
            setDynamicUi(val);
            setselectChoice(val);
       }

       useEffect(()=>{
         const ajax = axios({
           method:"GET",
           url:"/allCompQuestions"
         });

         ajax.then((response)=>{
           const tempData = [];
           response.data.compQuestions.filter((item) =>
           {
               if(item.questionType === "multiple choice")
               {
                  tempData.push(item);
               }
           });
           console.log(tempData);
         });
       },[]);





        return (
          <div className="ms-content-wrapper pr-5">
              <div className="row">
                  <div className="col-md-12">
                      <div className="ms-panel">
                        <div className="ms-panel-body">
                          <div>
                            <div className="mb-4 d-flex align-items-center">
                              <div className="border p-1 w-75" style={{height:"35px"}}>
                                <p className="p-0 m-0 text-center">
                                  Please select questionnaire type you want to add
                                </p>
                              </div>
                              <select className="form-control rounded-0" style={{width:"220px"}} value={selectChoice} onChange={handleUi}>
                                <option>Select questionnaire type</option>
                                {
                                  option.map((data,index)=>(
                                    <option key={index}>{data}</option>
                                  ))
                                }
                              </select>
                            </div>
                          </div>
                          <div style={{overflowY:"auto",overflowX:"hidden"}}>
                            <div>
                              {dynamicUi=== "range-slider" && <RangeSliderUi questionData={questionData} editMode={editMode} propsData={props.data} />}
                              {dynamicUi=== "toggle choice" && <ToggleQuestion questionData={questionData} editMode={editMode} propsData={props.data} />}
                              {dynamicUi=== "rank choice" && <RankChoose questionData={questionData} editMode={editMode} propsData={props.data} />}
                              {dynamicUi=== "paragraph" && <PragraphContent questionData={questionData} editMode={editMode} propsData={props.data} />}
                              {dynamicUi=== "single choice" && <SingleChoiceContent questionData={questionData} editMode={editMode} propsData={props.data} />}
                              {dynamicUi=== "multiple choice" && <MultipleChoiceContent questionData={questionData} editMode={editMode} propsData={props.data} />}


                            </div>
                          </div>

                        </div>
                      </div>
                  </div>
              </div>


          </div>

        );

}

export default QuestionsContent;
