import React from 'react';
import "./ChatOnline.css";

const ChatOnline=()=>
{
   return(
     <div className="chatOnline">

      <div className="chatOnlineFriend">
        <div className="chatOnlineImgContainer">
          <img className="chatOnlineImg"  src="https://www.w3schools.com/w3images/avatar2.png" alt="user"/>
          <div className="chatOnlineBadge"></div>
        </div>
        <span className="chatOnlineName">John doe</span>
      </div>
      
    </div>
   )
}

export default ChatOnline;
