import React from 'react';
import "./ChatButton.css";
import {Link} from "react-router-dom";
const ChatButton=()=>
{
    return(
      <div className="chat_btn">
        <Link to="/chat">
          <div className="dashboard_icon d-flex align-items-center justify-content-center">
              <span className="material-icons" style={{fontSize:"30px"}}>
                 textsms
               </span>
          </div>
        </Link>
      </div>
    )
}

export default ChatButton;
