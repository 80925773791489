import React from 'react';
import "../../../assets/css/Popup.css";

const Popup=({msg})=>
{
   return(
     <div className="w-100 notice d-none">
       <div className="notice_popup p-2">
          <div className="alert alert-success rounded-0 w-100 text-center">
            {msg}
          </div>
       </div>
     </div>
   )
}

export default Popup;
