import React from 'react';
import "../../../assets/css/CustomPageloader.css";
const CustomPageloader =()=>
{
     return(
       <div>
         <div className="loader">
             <div></div>
         </div>
       </div>
     )
}

export default CustomPageloader;
