import React,{useState,useEffect} from 'react';
// import { EditorState,convertToRaw,convertFromRaw} from 'draft-js';
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import { Editor } from 'react-draft-wysiwyg';
// import "../../../assets/css/editor.css";
import CustomRequestLoader from "../customloader/CustomRequestLoader";
import Popup from "../customloader/Popup";
import useAjaxHook from "../CustomHooks/useAjaxhook";
import axios from "axios";
import $ from "jquery";

const Content =(props)=>
{
       const {storedata,updatedata} = useAjaxHook();
       const [loader,setLoader] = useState(false);
       // const [editorState,setEditorState] = useState(() => EditorState.createEmpty());
       const [check,setCheck] = useState(false);
       const [pageName,setPageName] = useState([]);
       const [TemppageName,setTempPageName] = useState([]);

       const [inputData,setInputData] = useState({
         pageContent:"",
         pageName:"Select page name",
         status:true,
         requestType:"POST",
         id:""
       });

       const [btn,setbtn] = useState(false);
       const [Submitbtn,setSubmit] = useState(true);

       const [msg,setMsg] = useState("");

       useEffect(()=>{
         const ajax = axios({
           method:"GET",
           url:"https://datingapi.lipsticksncufflinks.com/getTextPages"
         });

         ajax.then((response)=>{
           const data = response.data.TextContent;
           setPageName(data);
           setTempPageName(data);
         });
       },[]);



       // const controlTextEditor=(editorState)=>
       // {
       //     setEditorState(editorState);
       //     let content = editorState.getCurrentContent();
       //     content = JSON.stringify(convertToRaw(content));
       //     setInputData({...inputData,pageContent:JSON.stringify(content)});
       // }

       const createAppTextcontent=(event)=>
       {
           event.preventDefault();
           let formdata = inputData;
           const url = "https://datingapi.lipsticksncufflinks.com/addTextContent"
           setLoader(true);
           if(inputData.requestType === "POST")
           {
              if(inputData.pageName !== "Select page name")
              {
                const ajax = storedata(url,formdata);
                ajax.then((response)=>{
                  if(response.data.status === 201)
                  {
                      setInputData({
                        pageContent:"",
                        pageName:"Select page name",
                        status:true,
                        requestType:"POST",
                        id:""
                      });
                     // setEditorState(() => EditorState.createEmpty());
                     setLoader(false);
                     $(".notice").removeClass("d-none");
                     setMsg("Record created");
                     removeMsg();
                     setPageName(TemppageName);
                  }
                  else
                  {
                      setLoader(false);
                      $(".notice").removeClass("d-none");
                      setMsg("Something went wrong try again");
                      removeMsg();
                  }
                });

                ajax.catch((error)=>{
                  if(error)
                  {
                      $(".notice").removeClass("d-none");
                      setMsg("Something went wrong try again");
                      removeMsg();
                      setLoader(false);
                  }
                });
              }
              else
              {
                $(".notice").removeClass("d-none");
                setMsg("Please select page name");
                removeMsg();
                setLoader(false);
              }
           }

           if(inputData.requestType === "PUT")
           {
              const id  = inputData.id;
              const url = "https://datingapi.lipsticksncufflinks.com/textContent/update/"+id;
              const ajax = updatedata(url,inputData);
              ajax.then((response)=>{
                if(response.data.status === 200)
                {
                    setInputData({
                      pageContent:"",
                      pageName:"Select page name",
                      status:true,
                      requestType:"POST",
                      id:""
                    });
                   // setEditorState(() => EditorState.createEmpty());
                   setLoader(false);
                   $(".notice").removeClass("d-none");
                   setMsg("Update success");
                   removeMsg();
                   setPageName(TemppageName);
                   setSubmit(true);
                   setbtn(false);
                }
                else
                {
                    setLoader(false);
                    $(".notice").removeClass("d-none");
                    setMsg("Something went wrong try again");
                    removeMsg();
                }
              });

              ajax.catch((error)=>{
                if(error)
                {
                  $(".notice").removeClass("d-none");
                  setMsg("Something went wrong try again");
                  removeMsg();
                }
              });
           }


       }


        // const getFileBase64 = (file, callback) => {
        //   var reader = new FileReader();
        //   reader.readAsDataURL(file);
        //   reader.onload = () => callback(reader.result);
        //   reader.onerror = error => {};
        // };
        //
        // const imageUploadCallback = file => new Promise(
        //   (resolve, reject) => getFileBase64(
        //     file,
        //     data => resolve({ data: { link: data } })
        //   )
        // );

        const editContent=()=>
        {
           setCheck(false);
           setbtn(true);
        }

        const handleInput=(event)=>
        {
           const name = event.target.name;
           const val  = event.target.value;
           setInputData({...inputData,[name]:val});
        }

        const removeMsg=()=>
        {
            setTimeout(()=>{
              setMsg("");
              $(".notice").addClass("d-none");
            },1000);
        }

        const handlePageEditing=(event)=>
        {
            const p_name = event.target.value;
            if(p_name !== "Select page name")
            {
              setInputData({...inputData,pageName:p_name});
              setLoader(true);
              const ajax = axios({
                method:"GET",
                url:"https://datingapi.lipsticksncufflinks.com/getPageByPageName/"+p_name
              });

              ajax.then((response)=>{
                setLoader(false);
                if(response.data.status === 400)
                {
                   setCheck(false);
                   setSubmit(true);
                   setbtn(false);
                }

                if(response.data.status === 200)
                {
                  setSubmit(false);
                  setCheck(true);
                  const data = response.data.TextContent[0];
                   setInputData({
                     pageContent:data.pageContent,
                     pageName:data.pageName,
                     version:data.version,
                     status:true,
                     requestType:"PUT",
                     id:data._id
                   });
                   // const page_content = EditorState.createWithContent(convertFromRaw(JSON.parse(data.pageContent)));
                   // setEditorState(page_content);

                   const newPagedata = pageName.filter((item,i) => {
                      return p_name === item;
                   });
                   setPageName(newPagedata);
                }

              });

              ajax.catch((error)=>{
                if(error)
                {
                   setLoader(false);
                   setSubmit(false);
                   setCheck(true);
                   setbtn(false);
                }
              });
            }

            else
            {
               setPageName(TemppageName);
               setInputData({
                 pageContent:"",
                 pageName:"Select page name",
                 status:true,
                 requestType:"POST",
                 id:""
               });
               // setEditorState(() => EditorState.createEmpty());
               $(".notice").removeClass("d-none");
               setMsg("Please select any page name");
               removeMsg();
               setCheck(false);
               setSubmit(true);
               setbtn(false);
            }

        }

        const cancelEditing=()=>
        {
            setInputData({
              pageContent:"",
              pageName:"Select page name",
              status:true,
              requestType:"POST",
              id:""
            });
            setPageName(TemppageName);
            setCheck(false);
            setSubmit(true);
            setbtn(false);
        }

        return (
          <div className="ms-content-wrapper pr-5">
              <div className="row">
                <Popup msg={msg}/>
                  <div className="col-md-12">
                      <div className="ms-panel">

                          <div className="ms-panel-body px-4">
                            <h4 className="mb-4">Manage pages</h4>
                            <form onSubmit={createAppTextcontent}>

                            <div className="d-flex">

                              <div className="form-group w-50">
                                <label>Page name</label>
                                <select className="form-control rounded-0" value={inputData.pageName} onChange={handlePageEditing} disabled={check} name="pageName">
                                  <option>Select page name</option>
                                  {
                                     pageName.map((data,index)=>(
                                       <option key={index}>{data}</option>
                                     ))
                                  }
                                </select>
                              </div>

                              <div className="form-group w-50 pl-2">
                                <label>Status</label>
                                <select className="form-control rounded-0" value={inputData.status} name="status" onChange={handleInput} disabled={check}>
                                  <option value={true}>Active</option>
                                  <option value={false}>Deactive</option>
                                </select>
                              </div>

                            </div>

                              <div className="mb-2">
                                <label>Page Description</label>
                                <input type="text" name="pageContent" disabled={check} value={inputData.pageContent} onChange={handleInput} className="form-control rounded-0" style={{height:"80px"}}/>
                              </div>


                            <div className="form-group mb-0">
                              {Submitbtn  &&
                              <button type="submit" className="btn btn-danger rounded-0 mr-2 mt-0">
                                Add page
                              </button>
                             }

                            <div className="d-flex">

                             {btn &&
                              <div>
                                <button type="submit" className="btn btn-info rounded-0 mr-2 mt-0">
                                  Update page
                                </button>

                                <button type="button" className="btn btn-dark rounded-0 mr-2 mt-0" onClick={cancelEditing}>
                                  Cancel
                                </button>
                              </div>
                             }

                             {check &&
                             <button onClick={editContent} className="btn mt-0 text-left" type="button">
                               <span className="material-icons" style={{fontSize:"25px"}}>create</span>
                             </button>
                            }
                           </div>

                            </div>

                            </form>
                          </div>
                      </div>
                  </div>
              </div>

              <div className={loader ? '' : 'd-none'}>
                <CustomRequestLoader/>
              </div>

          </div>

        );

}

export default Content;
