import React,{useEffect,useState} from 'react';
import useAjaxhook from "../CustomHooks/useAjaxhook";
import MaterialTable from 'material-table';
import $ from "jquery";
import CustomRequestLoader from "../customloader/CustomRequestLoader";
import AgentModel from "./AgentModel";

const ShowContent =()=>
{
     const [alldata,setAlldata] = useState([]);
     const [searchData,setSearchData] = useState([]);
     const {getalldata,updatedata} = useAjaxhook();
     const [userData,setUserData] = useState("");
     const [loader,setLoader] = useState(false);
     const [show, setShow] = useState(false);
     const [editMode,setEditMode] = useState("");
     const [modalData,setModalData] = useState("");

     const columns = [
       {title:"Name",field:"firstName"},
       {title:"Email",field:"email"},
       {title:"Mobile",field:"mobile"},
       {title:"Status",render: rowData =><input type='checkbox' checked={rowData.status} onChange={()=>{handleStatus(rowData)}} />},
     ];



    const handleClose = () => {
      setShow(false);
      setModalData("");
      setEditMode("");
    };
    const handleShow = () => setShow(true);


      useEffect(()=>{

        if(alldata.length === 0)
        {
            const url = "https://datingapi.lipsticksncufflinks.com/liveAgent/getLiveAgents";
            const ajax = getalldata(url);
            ajax.then((response)=>{
               if(response.data.status === 200)
               {
                   const data = response.data.liveAgent;
                   setAlldata(data);
                   const userd = data[0];
                   setUserData(userd);
                   setSearchData(data);
               }
            });

        }
      },[alldata,getalldata]);

      const getUserProfile=(data,keyName)=>
      {
          if(data !== "")
          {
              setModalData(data);
              setEditMode(keyName);
              handleShow();
          }

          else
          {
             const ind = document.querySelector(".s_username").selectedIndex;
             const alloption = $(".s_username option");
             const c_index = $(alloption[ind]).attr("index");
             const userd = alldata[c_index];
             setUserData(userd);
          }

      }

      const handleModal=()=>
      {
         handleShow();
         setModalData("");
         setEditMode("");
      }


      const handleStatus=(data)=>
      {
         const index = data.tableData.id;
         const status = !data.status;
         const update = [...searchData];
         update[index] = {...data,status:status};
         const id = alldata[index].userId;

         setLoader(true);
         const url = "https://datingapi.lipsticksncufflinks.com/liveAgent/updateLiveAgentByUserId/"+id;
         const ajax = updatedata(url,{status:status});
         ajax.then((response)=>{
          if(response.data.status === 200)
          {
              setSearchData(update);
              setLoader(false);
              const newData = alldata.map((obj)=>{
                return obj._id === data._id ? {...obj,status:status} : obj;
              });
              setAlldata(newData);
          }
          else
          {
            setLoader(false);
          }
         });

         ajax.catch((error)=>{
           if(error)
           {
              setLoader(false);
           }
         });

      }

      const handleSearchStatus=(event)=>
      {
         const input = event.target;
         if(input.value !== "Select status")
         {
             if(input.value === "Active")
             {
                  const newData = alldata.filter((item,index) => {
                    return item.status;
                  });

                  setSearchData(newData);
             }

             if(input.value === "Deactive")
             {
               const newData = alldata.filter((item,index) => {
                 return item.status !== true;
               });

               setSearchData(newData);
             }
         }

         else
         {
            setSearchData([...alldata]);
         }
      }

      const addLatestRecord=(data)=>
      {
          const newAllData = alldata.concat(data);
          setAlldata(newAllData);

          const sData = searchData.concat(data);
          setSearchData(sData);
      }

      const updateAgentRecord=(data)=>
      {
            const newData = alldata.map((obj)=>{
              return data.userId === obj.userId ? {...obj,...data} : obj;
            });

            const sData = searchData.map((obj)=>{
              return data.userId === obj.userId ? {...obj,...data} : obj;
            });

            if(userData.userId === data.userId)
            {
                setUserData({...userData,...data});
            }
            setAlldata(newData);
            setSearchData(sData);
      }

        return (
            <div className="ms-content-wrapper">
                <div className="row">
                    <div className="col-md-12">
                        <div className="ms-panel">
                            <div className="ms-panel-body">

                              <div className="d-flex justify-content-between align-items-center  py-2">
                                <h4 className="p-0 m-0">Live agent</h4>
                                <button className="btn btn-danger rounded-0 m-0 d-flex align-items-center" onClick={handleModal}>
                                  <span className="material-icons text-white mr-2" style={{fontSize:"20px"}}>add_circle</span>Add new agent
                                </button>
                              </div>

                              <div className="card rounded-0">
                                <div className="card-body">
                                  <div className="top_data d-flex mt-4 justify-content-center align-items-center">

                                    <div className="d-flex align-items-center justify-content-center rounded-circle mr-4" style={{width:"120px",height:"120px",backgroundColor:"#ccc"}}>
                                      <img src={userData.profileImage} className="rounded-circle" alt="userprofile" style={{width:"100%",height:"100%"}} />
                                    </div>

                                    <div>
                                      {userData !== "" &&
                                      <div className="mb-1">
                                         <span className="text-white rounded-circle text-center text-uppercase px-2 py-1" style={{width:"15px",height:"15px",backgroundColor:"#5939B6"}}>
                                           {userData.firstName[0]}
                                         </span>
                                         <span className="ml-1">{userData.firstName}</span>
                                         <span className="ml-1">{userData.lastName}</span>

                                         <div className="mt-2 d-flex align-items-center">
                                           <span className="text-white rounded-circle p-2 material-icons d-flex justify-content-center align-items-center" style={{width:"25px",height:"25px",backgroundColor:"#5939B6",fontSize:"13px"}}>
                                             phone_iphone
                                           </span>
                                           <span className="ml-1">{userData.mobile}</span>
                                         </div>

                                         <div className="mt-2 d-flex align-items-center">
                                           <span className="text-white rounded-circle p-2 material-icons d-flex justify-content-center align-items-center" style={{width:"25px",height:"25px",backgroundColor:"#5939B6",fontSize:"13px"}}>
                                             email
                                           </span>
                                           <span className="ml-1">{userData.email}</span>
                                         </div>

                                      </div>
                                    }
                                    </div>

                                  </div>

                                  <div className="my-4">
                                    <div className="d-flex justify-content-between">

                                      <select className="form-control rounded-0 mx-2 s_username" onChange={()=>{getUserProfile("","")}}>
                                        <option>Select username</option>
                                        {
                                           alldata.map((udata,i)=>(
                                             <option key={udata.id} index={i}>{udata.firstName}</option>
                                           ))
                                        }
                                      </select>

                                      <select className="form-control rounded-0" onChange={handleSearchStatus}>
                                        <option>Select status</option>
                                        <option>Active</option>
                                        <option>Deactive</option>
                                      </select>
                                  </div>

                                    <div className="my-4">
                                      <MaterialTable
                                        title="Agents"
                                        columns={columns}
                                        data={searchData}
                                        actions={[
                                          {icon: 'visibility',tooltip: 'View user',onClick: (event, rowData) => {getUserProfile(rowData,'view')}},
                                          {icon: 'create',tooltip: 'View user',onClick: (event, rowData) => {getUserProfile(rowData,'edit')}},
                                        ]}
                                        options={{
                                          actionsColumnIndex: -1
                                        }}
                                        />
                                    </div>

                                  </div>

                                </div>
                              </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className={loader ? '' : 'd-none'}>
                  <CustomRequestLoader/>
                </div>

                <AgentModel
                  show={show}
                  handleClose={handleClose}
                  addLatestRecord={addLatestRecord}
                  editMode={editMode}
                  modalData={modalData}
                  updateAgentRecord={updateAgentRecord}
                  />



            </div>

        );

}

export default ShowContent;
