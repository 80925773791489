import {
  LOGIN_BEGIN,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  RESET_ERROR
} from '../action/authAction'

const initialState = {
  isLoggedIn: false,
  user_details: {},
  error: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_BEGIN:
      return {
        ...state,
        isLoggedIn: true,
        error: {}
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        user_details: action.payload,
        isLoggedIn: true
      }
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,

        error: action.payload
      }
    case LOGOUT_SUCCESS:
      return {
        ...state,
        user_details: {},
        isLoggedIn: false
      }
    case RESET_ERROR:
      return {
        ...state,
        error:{}
      }
    default:
      return state
  }
}