import React, { Component } from 'react';
import Content from '../sections/Error/Content';

class Error extends Component {
    render() {
        return (
            <Content/>
        );
    }
}

export default Error;