import React,{useState,useEffect} from 'react';
import {Card,ListGroup,Modal,Button} from "react-bootstrap";
import axios from "axios";
import {Spinner} from "react-bootstrap";
import Swal from 'sweetalert2';

const InvesmentList=()=>
{

  const [alldata,setAlldata] = useState([]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [loader,setLoader] = useState(false);
  const [updateData,setUpdateData] = useState(null);
  const [updateId,setUpdateId] = useState(null);

  const [inputData,setInputData] = useState({
    subscriptionName:"",
    price: "",
    subscriptionType: "Free",
    subscriptionDays: ""
  });

  const [featuresPoints,setfeaturesPoints] = useState([
    {
      featureName:""
    }
  ]);


  const handleFeaturePoints=(event,index)=>
  {
     const val = event.target.value;
     const data = [...featuresPoints];
     data[index] = {featureName:val};
     setfeaturesPoints(data);
  }

  const handleSubmit=(event)=>
  {
      event.preventDefault();
      setLoader(true);
      const ajax = axios({
        method:"PUT",
        url:"/updateSubscriptionById/"+updateId,
        data:{
          ...inputData,
          featuresPoints:featuresPoints,
          status:true
        }
      });

      ajax.then((response)=>{
        Swal.fire(response.data.message);
        if(response.status ===200)
        {
           setInputData({
             subscriptionName:"",
             price: "",
             subscriptionType: "Free",
             subscriptionDays: ""
           });

           setfeaturesPoints(
             [
               {
                 featureName:""
               }
             ]
           );
        }
      });

      ajax.catch((error)=>{
        console.log(error);
      });

      ajax.finally(()=>{
        setLoader(false);
        setUpdateData(new Date());
      });
  }

  const removePoints=(index)=>
  {
     const newData = featuresPoints.filter((item,i) => {
       return i !== index;
     });

     return setfeaturesPoints(newData);
  }

  const handleInput=(event)=>
  {
     const name = event.target.name;
     const val  = event.target.value;
     return setInputData((oldData)=>{
       return {
         ...oldData,
         [name]:val
       }
     });
  }

  const addPoints=()=>
  {
     const oldData = [...featuresPoints];
     oldData.push({featureName:""});
     setfeaturesPoints(oldData);
  }

  const EditContent=(id)=>
  {
     setUpdateId(id);
     const ajax = axios({
       method:"GET",
       url:"/getSubscriptionById/"+id
     });

     ajax.then((res)=>{

       if(res.status===200)
       {
          const data = res.data.data;
          const price = data.price;
          const subscriptionDays = data.subscriptionDays;
          const subscriptionName = data.subscriptionName;
          const subscriptionType = data.subscriptionType;
          const points = data.featuresPoints;
          setInputData({
            price:price,
            subscriptionDays:subscriptionDays,
            subscriptionName:subscriptionName,
            subscriptionType:subscriptionType
          });

          setfeaturesPoints(points);

       }
     });

     ajax.finally(()=>{
       setShow(true);
     });

  }

  const DeleteContent=(id)=>
  {

      Swal.fire({
        title: 'Are you sure?',
        text: "",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) =>
      {
        if (result.isConfirmed)
        {

          const ajax = axios({
            method:"DELETE",
            url:"/deleteSubscriptionById/"+id
          });

          ajax.then((res)=>{
            Swal.fire(
              'Deleted!',
              res.data.message,
              'success'
            );
            setUpdateData(new Date());
          });

        }
      });


  }


  useEffect(()=>{
    const ajax = axios({
      method:"GET",
      url:"/getAllSubscriptions"
    });

    ajax.then((response)=>{
      if(response.data.status === 200)
      {
         const data = response.data.data;
         setAlldata(data);
      }
    });

  },[updateData]);

  const CardDesign=({investmentdata})=>
  {
     const design = (
       <>
      <Card>

       <Card.Header>
         <div className="float-right">
           <i className="fa fa-edit mr-2" onClick={()=>EditContent(investmentdata._id)}></i>
           <i className="fa fa-trash" onClick={()=>DeleteContent(investmentdata._id)}></i>
         </div>
       </Card.Header>

       <Card.Body>
         <h6>
           Plan Name : {investmentdata.subscriptionName}
         </h6>
         <h6>Plan Type : {investmentdata.subscriptionType}</h6>
         <h6>Days : {investmentdata.subscriptionDays}</h6>
         <h6>Plan Price : {investmentdata.price}</h6>
         <h6>Plan Features</h6>
           {
              investmentdata.featuresPoints.map((data,index)=>{
                return  <ListGroup as="ul" key={index}>
                           <ListGroup.Item as="li">
                             {data.featureName}
                           </ListGroup.Item>
                         </ListGroup>
              })
           }


       </Card.Body>
      </Card>
       </>
     );

     return design;
  }

   const ui=(
     <div className="ms-content-wrapper">
         <div className="row">
           <div className="col-md-12">
               <div className="ms-panel p-4 ">
                 <div className="row">
                   {
                     alldata.map((item,index)=>{
                       return (
                         <div className="col-md-4 mb-2" key={item._id}>
                           <CardDesign investmentdata={item}/>
                         </div>
                       )
                     })
                   }
                 </div>
               </div>
             </div>
           </div>

           <Modal show={show} onHide={handleClose} size="lg">

            <Modal.Header closeButton>
              <Modal.Title>Modal heading</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <form onSubmit={handleSubmit}>

                  <div className="row">

                    <div className="col-md-6">

                      <div className="form-group">
                        <label>Subscription name</label>
                        <input type="text"
                          name="subscriptionName"
                          className="form-control rounded-0"
                          onChange={handleInput}
                          value={inputData.subscriptionName}
                          required
                          />
                      </div>

                      <div className="form-group">
                        <label>Subscription Days</label>
                        <input type="text"
                          name="subscriptionDays"
                          className="form-control rounded-0"
                          onChange={handleInput}
                          value={inputData.subscriptionDays}
                          required
                          />
                      </div>

                    </div>

                    <div className="col-md-6">

                      <div className="form-group">
                        <label>Subscription Type</label>
                        <select className="form-control" name="subscriptionType" onChange={handleInput} value={inputData.subscriptionType}>
                          <option>Select Subscription Type</option>
                          <option value="Free">Free</option>
                          <option value="Month">Month</option>
                          <option value="Concierge">Concierge</option>
                        </select>
                      </div>

                      <div className="form-group">
                        <label>Price</label>
                        <input type="text"
                          name="price"
                          className="form-control rounded-0"
                          onChange={handleInput}
                          value={inputData.price}
                          required
                          />
                      </div>

                    </div>

                    {
                      featuresPoints.map((data,index)=>{
                      return  (
                        <>
                        <div className="col-md-6 mb-2" key={index}>
                          <input
                            placeholder="Enter Features"
                            className="form-control rounded-0"
                            type="text"
                            value={data.featureName}
                            onChange={(event)=>{handleFeaturePoints(event,index)}}
                            required
                            />
                        </div>

                        <div className="col-md-6">
                          <button type="button" className="btn-danger" onClick={()=>{removePoints(index)}}>
                            <i className="fa fa-minus"></i>
                          </button>
                        </div>
                        </>
                      )
                      })
                    }

                    <div className="col-md-12">
                      <center>
                        <button type="button" className="btn btn-danger rounded-0" onClick={addPoints} style={{marginRight:"10px"}}>
                          Add More Feature
                        </button>

                        {
                          loader ?   <Button className="btn btn-danger rounded-0" disabled>
                                     <Spinner
                                       as="span"
                                       animation="border"
                                       size="sm"
                                       role="status"
                                       aria-hidden="true"
                                     />
                                     <span className="visually-hidden">Loading...</span>
                                    </Button> :  <button type="submit" className="btn btn-danger rounded-0">
                                                     Save Plan
                                                   </button>
                        }


                      </center>
                    </div>

                  </div>


                </form>
            </Modal.Body>

          </Modal>


         </div>
   );

   return ui;
}

export default InvesmentList;
