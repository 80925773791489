import React from 'react';

const Conversation=(props)=>
{
    const {userData,uindex,acceptChatWithUser} = props;
    return(
      <div className="conversation mb-3" onClick={()=>{acceptChatWithUser(userData[uindex],uindex)}}>
        <div className="px-1" style={{position:"relative"}}>
          {userData[uindex].newMsg &&
          <span className="bg-danger rounded-circle p-2" style={{position:"absolute",top:"5px",right:"10px"}}></span>
          }
          <div className="row py-2">
            <div className="col-md-3">
              <img src={`${userData[uindex].profileImage}`} alt="user" className="conversation_img mr-3" />
            </div>

            <div className="col-md-9">
              <h6 className="p-0 m-0 conversation_name" >{userData[uindex].fullName}</h6>
              <p className="p-0 m-0">{userData[uindex].message}</p>
            </div>

          </div>
        </div>
      </div>
    )
}

export default Conversation;
