import React from 'react';
import "../../../assets/css/customRequestLoader.css";

const CustomRequestLoader=()=>
{
    return(
      <div className="requestloader">
        <div className="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
      </div>
    )
}

export default CustomRequestLoader;
