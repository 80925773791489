import axios from "axios";

const useAjaxhook=()=>
{

     const StoreData=(url,formdata)=>
     {
         const ajax = axios({
           method:"POST",
           url:url,
           data:{
             ...formdata
           }
         });

         return ajax;

     }

     const getAllData=(url)=>
     {
        const ajax = axios({
          method:"GET",
          url:url
        });

        return ajax;

     }

     const UpdateData=(url,data)=>
     {
         const ajax = axios({
           method:"PUT",
           url:url,
           data:{
             ...data
           }
         });

         return ajax;

     }

     const deleteData=(url)=>
     {
         const ajax = axios({
           method:"DELETE",
           url:url,
         });

         return ajax;

     }


     return{
       storedata:StoreData,
       getalldata:getAllData,
       updatedata:UpdateData,
       deletedata:deleteData
     }
}

export default useAjaxhook;
