import React,{useState,useEffect} from 'react';
import useAjaxhook from "../CustomHooks/useAjaxhook";
import {Link} from "react-router-dom";

const Question=()=>
{

  const {getalldata} = useAjaxhook();

  useEffect(()=>{
    getQuestion();
  },[]);

  const [singleChoice,setsingleChoice] = useState(0);
  const [multipleChoice,setmultipleChoice] = useState(0);
  const [paragraph,setParagraph] = useState(0);

  const getQuestion=()=>
  {
    const url = "https://datingapi.lipsticksncufflinks.com/allCompQuestions";
    const ajax = getalldata(url);
    ajax.then((response)=>{
       if(response.data.status === 200)
       {
           const data = response.data.compQuestions;
           let pcount = 0;
           let scount = 0;
           let mcount = 0;

           data.forEach((item)=>{
             if(item.questionType === "single choice")
             {
               scount +=1;
             }

             if(item.questionType === "paragraph")
             {
               pcount +=1
             }

             if(item.questionType === "multiple choice")
             {
               mcount +=1
             }

           });

           setsingleChoice(scount);
           setmultipleChoice(mcount);
           setParagraph(pcount);
       }
    });
  }

  return(
    <div className="col-md-4 mb-5">
        <div className="card rounded-0 border-0 shadow-lg py-3">
        <div className="card-body container_box pr-0">
          <div className="dashboard_icon mb-3">
              <span className="material-icons qus_icon">
                 quize
               </span>
          </div>

          <div>
            <h6 className="text-center float-right border p-3">
              <Link to='/add-new-question'>Questionnaire</Link>
            </h6>
          </div>

        </div>
      </div>
    </div>
  )
}

export default Question;
