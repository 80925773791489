import React from 'react';
import "./message.css";
import { format } from "timeago.js";

const Message=(props)=>
{
   const {chatData,chatIndex,own} = props;
   return(
     <div className={own ? "message own" : "message"}>

        <div className="messageTop">
          <p className="messageText">
            {chatData.message}
          </p>
        </div>

        <div className="messageBottom">
          {format(chatData.createdAt)}
        </div>

     </div>
   )
}

export default Message;
