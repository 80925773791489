import React,{useState,useEffect} from 'react';
import axios from "axios";
import Swal from 'sweetalert2';
import {Button,Spinner,Table} from "react-bootstrap";
import MaterialTable from 'material-table'

const MemberProfileQusContent=()=>
{

  const [loader,setLoader] = useState(false);
  const [alldData,setAllData] = useState([]);
  const [updateDate,setUpdateDate] = useState(new Date());

  const [inputData,setInputData] = useState({
    optionName_one:"",
    optionName_two:""
  });

const [columns, setColumns] = useState([
  {title: 'Option 1', field: 'optionName_one'},
  {title: 'Option 2', field: 'optionName_two'},
]);

  useEffect(()=>{
    const ajax = axios({
      method:"GET",
      url:"/getAllMemberProfileQuestions",
    });

    ajax.then((response)=>{
      if(response.status === 200)
      {
         let data = response.data.data;
         let TempData = [];
         data.forEach((item, i) =>
         {
             console.log(item);
             let op_1 = item.options[0].optionName;
             let op_2 = item.options[1].optionName;
             let id = item._id;
             let status = item.status;
             TempData.push({optionName_one:op_1,optionName_two:op_2,id:id,status:status});
         });
         // console.log(TempData);
         setAllData(TempData);
      }
    });

  },[updateDate]);

  const handleInput=(event)=>
  {
     let name = event.target.name;
     let val  = event.target.value;

     setInputData((oldData)=>{
       return {
         ...oldData,
         [name]:val
       }
     });
  }

  const submitData=(event)=>
  {
     event.preventDefault();
     setLoader(true);
     const ajax = axios({
       method:"POST",
       url:"/createMemberProfileQuestion",
       data:{
         options:[
           {optionName:inputData.optionName_one},
           {optionName:inputData.optionName_two}
         ]
       }
     });

     ajax.then((response)=>{
       Swal.fire(response.data.message);
     });

     ajax.finally(()=>{
       setLoader(false);
     })
  }


  const deleteItem=(event,id)=>
  {
      let status = event.target.checked;
      let id_number = id;
      window.alert(id);
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then((result) =>
      {
        if (result.isConfirmed)
        {

          const ajax = axios({
            method:"PUT",
            url:"/updateMemberProfileQuestionById/"+id_number,
            data:{
              status:status
            }
          });

          ajax.then((response)=>{
             console.log(response);
             Swal.fire(
               'Updated !',
               '',
               'success'
             );

             setUpdateDate(new Date());

          });


        }
      });


  }

   const ui = (
     <>
     <div className="ms-content-wrapper">
         <div className="row">

           <div className="col-md-12">
               <div className="ms-panel p-4 ">
                 <h4 className="text-center mt-2 mb-4">Create Member Profile Question</h4>
                  <div>
                   <form onSubmit={submitData}>
                     <div className="row">

                       <div className="col-md-6">
                         <label>Option 1</label>
                         <input type="text"
                           className="form-control rounded-0"
                           name="optionName_one"
                           onChange={handleInput}
                           value={inputData.optionName_one}
                           />
                       </div>

                       <div className="col-md-6">
                         <label>Option 2</label>
                         <input type="text"
                           className="form-control rounded-0"
                           name="optionName_two"
                           onChange={handleInput}
                           value={inputData.optionName_two}
                           />
                       </div>
                     </div>

                     <div className="col-md-12 text-center">

                     {
                         loader ? <Button variant="primary" disabled> <Spinner as="span" animation="grow" size="sm"role="status" aria-hidden="true"/> Loading...</Button> : <button className="btn btn-danger rounded-0">Submit</button>
                     }

                     </div>

                   </form>
                 </div>
               </div>
             </div>

          <div className="col-md-12">
            <Table striped bordered hover variant="dark" responsive>
              <thead>
                <tr>
                  <th>Option One</th>
                  <th>Option Two</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {alldData.map((item,index)=>{
                  return(
                    <tr key={item.id}>
                      <td>{item.optionName_one}</td>
                      <td>{item.optionName_two}</td>
                      <td>

                      <input type="checkbox"
                        checked={item.status}
                        onClick={(event)=>{deleteItem(event,item.id)}}
                         />

                      </td>
                    </tr>
                  )
                })}
              </tbody>

            </Table>
          </div>


         </div>
       </div>
     </>
   );
   return ui;
}

export default MemberProfileQusContent;
