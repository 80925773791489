import React,{useState,useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';
import useAjaxhook from "../CustomHooks/useAjaxhook";
import CustomRequestLoader from "../customloader/CustomRequestLoader";
import { validPassword } from './Regex.js';
import $ from "jquery";
import axios from "axios";
import Popup from "../customloader/Popup";

const AgentModel=(props)=>
{

   const {storedata,updatedata} = useAjaxhook();
   const {show,handleClose,addLatestRecord,editMode,modalData,updateAgentRecord} = props;
   const [loader,setLoader] = useState(false);
   const [passwdNotice,setPasswdNotice] = useState("");
   const [passwdColor,setPasswdColor] = useState("");
   const [mobileNotice,setMobileNotice] = useState("");
   const [profileNotice,setprofileNotice] = useState("");
   const [emailNotice,setEmailNotice] = useState("");
   const [check,setCheck] = useState(false);
   const [btn,setBtn] = useState(false);
   const [submitBtn,setSubmitBtn] = useState(true);

   const [msg,setMsg] = useState("");

   const [inputData,setInputData] = useState({
     "firstName":"",
     "lastName": "",
     "email":"",
     "mobile":"",
     "password":"",
     "gender": "Male",
     "address": "",
     "dateOfBirth": "",
     "location": "",
     "profileImage":"",
     "status": true,
     "requestType":"POST",
     "id":""
   });

   useEffect(()=>{
     if(editMode !== "")
     {
       if(editMode === "view")
       {
          setInputData({
            "firstName":modalData.firstName,
            "lastName":modalData.lastName,
            "email":modalData.email,
            "mobile":modalData.mobile,
            "password":"",
            "gender":modalData.gender,
            "address":modalData.address,
            "dateOfBirth":modalData.dateOfBirth,
            "location":modalData.location,
            "profileImage":modalData.profileImage,
            "status": true,
            "requestType":"POST",
            "id":""
          });
          setCheck(true);
          setSubmitBtn(false);
          setBtn(false);
       }

       if(editMode === "edit")
       {
          setInputData({
            "firstName":modalData.firstName,
            "lastName":modalData.lastName,
            "email":modalData.email,
            "mobile":modalData.mobile,
            "password":"",
            "gender":modalData.gender,
            "address":modalData.address,
            "dateOfBirth":modalData.dateOfBirth,
            "location":modalData.location,
            "profileImage":modalData.profileImage,
            "status": true,
            "requestType":"PUT",
            "id":modalData.userId
          });
          setCheck(false);
          setSubmitBtn(false);
          setBtn(true);
       }
     }

     else
     {
       setInputData({
         "firstName":"",
         "lastName":"",
         "email":"",
         "mobile":"",
         "password":"",
         "gender":"Male",
         "address":"",
         "dateOfBirth":"",
         "location":"",
         "profileImage":"",
         "status": true,
         "requestType":"POST",
         "id":""
       });
       setCheck(false);
       setSubmitBtn(true);
       setBtn(false);
     }
   },[editMode,modalData])

   const handleInput=(event)=>
   {
       const name = event.target.name;
       const val  = event.target.value;
       if(name !== "password" && name !== "mobile")
       {
         setInputData({...inputData,[name]:val});
       }

       if(name === "password")
       {
          setInputData({...inputData,[name]:val});
          setPasswdNotice("Provide password at least 6 digit");
          if(val.length >= 6)
          {
              setPasswdNotice("");
              if(!validPassword.test(val))
              {
                 setPasswdColor("bg-danger");
              }
              else
              {
                setPasswdColor("bg-success");
              }
          }

          else
          {
             setPasswdNotice("Provide password at least 6 digit");
             setPasswdColor("");
          }
       }


       if(name === "mobile")
       {
          setInputData({...inputData,[name]:val});
          setMobileNotice("Provide 10 digit mobile number");
          if(val.length >= 10)
          {
            setMobileNotice("");
          }
       }

       if(name === "email")
       {
          setInputData({...inputData,[name]:val});
          setEmailNotice("");
       }
   }

   const handleImage=(event)=>
   {
      if(event.target.files[0] !== undefined)
      {

        const input = event.target;
        event.persist();
        const file = event.target.files[0];
        let fname = file.name;
        let ftype = file.type;
        if(ftype === "image/jpg" || ftype === "image/png" || ftype === "image/gif" || ftype === "image/jpeg")
        {
            setLoader(true);
            setprofileNotice("");
            fname = fname.split(".")[0];
            let formdata = new FormData();
            formdata.append("avatar",file);
            formdata.append("name",fname);
            const ajax = axios({
              method:"POST",
              url:"https://datingmedia.lipsticksncufflinks.com/single-image",
              data:formdata
            });

            ajax.then((response)=>{
              const img_url = response.data.data.url;
              setInputData({...inputData,profileImage:img_url});
              setLoader(false);
            });

            ajax.catch((error)=>{
              if(error)
              {
                setInputData({...inputData,profileImage:""});
                setLoader(false);
              }
            });
        }

        else
        {
           setprofileNotice("Please upload image file");
           setInputData({...inputData,profileImage:""});
           $(input).val("");
        }
      }
      else
      {
        setInputData({...inputData,profileImage:""});
        setprofileNotice("Please upload image file");
      }

   }

   const createAgent=(event)=>
   {
      event.preventDefault();
      const frm = event.target;
      const notice = $(".val_notice",frm);
      if(notice.length === 0)
      {
        if(inputData.requestType === "POST")
        {
          const url = "https://datingapi.lipsticksncufflinks.com/liveAgent/addLiveAgent";
          const ajax = storedata(url,inputData);
          setLoader(true);
          ajax.then((response)=>{
            setLoader(false);
            if(response.data.status === 200)
            {
                const obj = response.data.MemberProfile;
                addLatestRecord({...obj,email:inputData.email,mobile:inputData.mobile});
                setMsg("Agent successfully created !");
                $(".notice").removeClass("d-none");
                removeMsg();
                setInputData({
                  "firstName":"",
                  "lastName": "",
                  "email":"",
                  "mobile":"",
                  "password":"",
                  "gender": "Male",
                  "address": "",
                  "dateOfBirth": "",
                  "location": "",
                  "profileImage":"",
                  "status": true,
                  "requestType":"POST",
                  "id":""
                });
                setPasswdNotice("");
                setEmailNotice("");
                setMobileNotice("");
                setPasswdColor("");
            }
            else
            {
               if(response.data.message === "Email already exists")
               {
                  setEmailNotice("Email already exists !");
               }

               if(response.data.message === "Mobile-no. already exists")
               {
                  setMobileNotice("Mobile number exists !");
               }

               setMsg("Something is wrong try again !");
               $(".notice").removeClass("d-none");
               removeMsg();
            }
          });

          ajax.catch((error)=>{
            if(error)
            {
                setMsg("Something is wrong try again !");
                $(".notice").removeClass("d-none");
                removeMsg();
                setLoader(false);
            }
          });

        }

        if(inputData.requestType === "PUT")
        {
          const url = "https://datingapi.lipsticksncufflinks.com/liveAgent/updateLiveAgentByUserId/"+inputData.id;
          const ajax = updatedata(url,inputData);
          setLoader(true);
          ajax.then((response)=>{
            setLoader(false);
            if(response.data.status === 200)
            {
              setMsg("Update successfully");
              $(".notice").removeClass("d-none");
              removeMsg();
              setInputData({
                "firstName":"",
                "lastName": "",
                "email":"",
                "mobile":"",
                "password":"",
                "gender": "Male",
                "address": "",
                "dateOfBirth": "",
                "location": "",
                "profileImage":"",
                "status": true,
                "requestType":"PUT",
                "id":""
              });
              const newData = response.data.data;
              updateAgentRecord(newData);
            }
          });

          ajax.catch((error)=>{
            if(error)
            {
                setLoader(false);
                setMsg("Update Failed");
                $(".notice").removeClass("d-none");
                removeMsg();
            }
          });

        }

      }

      else
      {
        setMsg("Something is wrong try again !");
        $(".notice").removeClass("d-none");
        removeMsg();
      }

   }

   const removeMsg=()=>
   {
      setTimeout(()=>{
        setMsg("");
        $(".notice").addClass("d-none");
      },3000);
   }

   return(
     <div className="agent_modal">
       <Modal show={show} onHide={handleClose} size="lg">

         <Modal.Header closeButton>
           <div className="d-flex justify-content-center  w-100">
             <div className="border rounded-circle shadow-sm d-flex justify-content-center align-items-center" style={{width:"100px",height:"100px"}}>
               {inputData.profileImage !== "" &&
                 <img src={inputData.profileImage} className="rounded-circle" alt="agent-profileimage" style={{width:"95px",height:"95px"}}/>
                }
             </div>
           </div>
         </Modal.Header>

         <Modal.Body>
           <form onSubmit={createAgent}>
          <div className="d-flex">
            <div className="w-50">
             <div className="input-group mb-3">
               <div className="input-group-prepend">
                 <span className="material-icons input-group-text rounded-0 border-right-0 bg-primary text-white">face</span>
               </div>
               <input type="text" className="form-control rounded-0" value={inputData.firstName} name="firstName" disabled={check} required="required" onChange={handleInput} placeholder="First Name"/>
             </div>
             </div>

             <div className="w-50 pl-2">
             <div className="input-group mb-3">
               <div className="input-group-prepend">
                 <span className="material-icons input-group-text rounded-0 border-right-0 bg-primary text-white">face</span>
               </div>
               <input type="text" className="form-control rounded-0" value={inputData.lastName} name="lastName" disabled={check} required="required" onChange={handleInput} placeholder="Last Name"/>
             </div>
             </div>
            </div>

          <div className="d-flex">
            <div className="w-50">
             <div className="input-group mb-3">
               <div className="input-group-prepend">
                 <span className="material-icons input-group-text rounded-0 border-right-0 bg-primary text-white">email</span>
               </div>
               <input type="email" className="form-control rounded-0" value={inputData.email} name="email" disabled={check} required="required" onChange={handleInput} placeholder="Email Id"/>
             </div>
            </div>

            <div className="w-50 pl-2">
             <div className="input-group mb-3">
               <div className="input-group-prepend">
                 <span className="material-icons input-group-text rounded-0 border-right-0 bg-primary text-white">settings</span>
               </div>
               <select className="form-control rounded-0" value={inputData.status} name="status" disabled={check} onChange={handleInput}>
                 <option value={true}>Active</option>
                 <option value={false}>Deactive</option>
               </select>
             </div>
           </div>

         </div>

         {emailNotice !== "" &&
         <p className="p-0 m-0 mb-3 d-flex align-items-center val_notice animate__animated animate__zoomIn">
           <span className="material-icons text-danger mr-1">error</span>
           <span className="text-danger">{emailNotice}</span>
         </p>
        }

         <div className="d-flex">
           <div className="w-50">
             <div className="input-group mb-3">
               <div className="input-group-prepend">
                 <span className="material-icons input-group-text rounded-0 border-right-0 bg-primary text-white">room</span>
               </div>
               <input type="text" className="form-control rounded-0" value={inputData.address} name="address" disabled={check} required="required" onChange={handleInput} placeholder="Address"/>
             </div>
            </div>

            <div className="w-50 pl-2">
             <div className="input-group mb-3">
               <div className="input-group-prepend">
                 <span className="material-icons input-group-text rounded-0 border-right-0 bg-primary text-white">fmd_good</span>
               </div>
               <input type="text" className="form-control rounded-0" value={inputData.location} name="location" disabled={check} required="required" onChange={handleInput} placeholder="Location"/>
             </div>
           </div>
         </div>

         <div className="d-flex">
           <div className="w-50">
             <label>Gender</label>
             <div className="input-group mb-3">
               <div className="input-group-prepend">
                 <span className="material-icons input-group-text rounded-0 border-right-0 bg-primary text-white">person</span>
               </div>
               <select className="form-control rounded-0" value={inputData.gender} name="gender" disabled={check} onChange={handleInput}>
                 <option>Male</option>
                 <option>Female</option>
                 <option>Other</option>
               </select>
             </div>
            </div>

            <div className="w-50 pl-2">
              <label>Date of birth</label>
             <div className="input-group mb-3">
               <div className="input-group-prepend">
                 <span className="material-icons input-group-text rounded-0 border-right-0 bg-primary text-white">date_range</span>
               </div>
               <input type="date" className="form-control rounded-0" value={inputData.dateOfBirth} name="dateOfBirth" disabled={check} required="required" onChange={handleInput}/>
             </div>
            </div>
          </div>

          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="material-icons input-group-text rounded-0 border-right-0 bg-primary text-white">file_upload</span>
            </div>
            <div className="custom-file">
              <label className="custom-file-label rounded-0" htmlFor="agent_profile">Upload profile picture</label>
              <input type="file" accept="image/*" className="custom-file-input rounded-0" id="agent_profile" disabled={check} onChange={handleImage}/>
            </div>
          </div>

          {profileNotice !== "" &&
          <p className="p-0 m-0 mb-3 d-flex align-items-center val_notice animate__animated animate__zoomIn">
            <span className="material-icons text-danger mr-1">error</span>
            <span className="text-danger">{profileNotice}</span>
          </p>
         }

             <div className="input-group mb-3">
               <div className="input-group-prepend">
                 <span className="material-icons input-group-text rounded-0 border-right-0 bg-primary text-white">phone_iphone</span>
               </div>
               <input type="number" className="form-control rounded-0" value={inputData.mobile} name="mobile" disabled={check} required="required" onChange={handleInput} placeholder="Mobile Number"/>
             </div>
             {mobileNotice !== "" &&
             <p className="p-0 m-0 d-flex align-items-center val_notice mb-3 animate__animated animate__bounce">
               <span className="material-icons text-danger mr-1">error</span>
               <span className="text-danger">{mobileNotice}</span>
             </p>
            }

            {editMode === "" &&
             <div className="input-group mb-3">
               <div className="input-group-prepend">
                 <span className="material-icons input-group-text rounded-0 border-right-0 bg-primary text-white">vpn_key</span>
               </div>
               <input type="password" className="form-control rounded-0" value={inputData.password} required="required" name="password" onChange={handleInput} placeholder="Password"/>
           </div>
         }
           {passwdNotice !== "" &&
           <p className="p-0 m-0 d-flex align-items-center val_notice animate__animated animate__zoomIn">
             <span className="material-icons text-danger mr-1">error</span>
             <span className="text-danger">{passwdNotice}</span>
           </p>
          }

          {passwdColor !== "" &&
           <div className="progress" style={{height:"10px"}}>
             <div className={`progress-bar w-100 ${passwdColor}`} style={{height:"10px"}}>
             </div>
           </div>
         }
             <div className="d-flex justify-content-end">

               {submitBtn &&
               <button className="btn btn-danger rounded-0 notice_btn mt-0" type="submit">
                 <span className="material-icons mr-2" style={{fontSize:"20px",color:"white"}}>person_add_alt</span>
                 Add Agent
               </button>
             }

             {btn &&
               <button className="btn btn-danger rounded-0 notice_btn mt-0" type="submit">
                 <span className="material-icons mr-2" style={{fontSize:"20px",color:"white"}}>save</span>
                 Save changes
               </button>
             }

             </div>
           </form>
           <div className="mt-2">
             <Popup msg={msg}/>
           </div>
         </Modal.Body>

       </Modal>

       <div className={loader ? '' : 'd-none'}>
         <CustomRequestLoader/>
       </div>

     </div>

   )
}

export default AgentModel;
