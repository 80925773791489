import React,{useEffect,useState} from 'react';
import {Link} from "react-router-dom";
import useAjaxHook from "../CustomHooks/useAjaxhook";
import MaterialTable from 'material-table';
import CustomRequestLoader from "../customloader/CustomRequestLoader";

const ShowContent =()=>
{
     const [alldata,setAlldata] = useState([]);
     const [loader,setLoader] = useState(false);
     const {getalldata,deletedata} = useAjaxHook();
     const columns = [
       {title:"Option",field:"minOptions"},
       {title:"Question",field:"question"},
       {title:"Question main type",field:"questionMainType"},
       {title:"Question type",field:"questionType"},
       {title:"Status",field:"status",lookup:{true:"Active",false:"Inactive"}}
     ];

      useEffect(()=>{
        if(alldata.length === 0)
        {

            const url  = "https://datingapi.lipsticksncufflinks.com/allCompQuestions";
            const ajax = getalldata(url);
            ajax.then((response)=>{
              if(response.data.status === 200)
              {
                const data = response.data.compQuestions;
                setAlldata(data);
              }
            });

        }
      },[alldata,getalldata]);

      const deleteQuestion=(index)=>
      {
         const confirm = window.confirm("Do you want to delete ?");
         if(confirm)
         {

           setLoader(true);
           const obj = alldata[index];
           const url = "https://datingapi.lipsticksncufflinks.com/compQuestion/delete/"+obj._id;
           const ajax = deletedata(url);
           ajax.then((response)=>{
             setLoader(false);
             if(response.data.status === 200)
             {
                const newData = alldata.filter((item,i) => {
                   return obj._id !== item._id;
                });
                setAlldata(newData);
             }
           });

           ajax.catch((error)=>{
             if(error)
             {
                setLoader(false);
             }
           });
         }

      }


        return (
            <div className="ms-content-wrapper">
                <div className="row">

                    <div className="col-md-12">
                        <div className="ms-panel">
                            <div className="ms-panel-header d-flex justify-content-end">
                              <button className="btn btn-success rounded-0">
                                <Link to="/add-new-question" className="text-dark">Add New</Link>
                              </button>
                            </div>

                            <div className="ms-panel-body">
                              <MaterialTable
                                title="Questions"
                                columns={columns}
                                data={alldata}
                                actions={[
                                  rowData => ({
                                    icon: () =><Link to={`/add-new-question/?view=${rowData._id}`}><span className="material-icons" style={{fontSize:"20px"}}>remove_red_eye</span></Link>,
                                    tooltip: 'View'
                                  }),
                                  rowData => ({
                                    icon: () =><Link to={`/add-new-question/?id=${rowData._id}`}><span className="material-icons" style={{fontSize:"20px"}}>create</span></Link>,
                                    tooltip: 'Edit '
                                  }),
                                  {icon: 'delete',tooltip: 'Delete question',onClick: (event, rowData) => {deleteQuestion(rowData.tableData.id)}},
                                ]}
                                options={{
                                  actionsColumnIndex: -1
                                }}
                                />
                            </div>
                        </div>
                    </div>

                </div>

                <div className={loader ? '' : 'd-none'}>
                  <CustomRequestLoader/>
                </div>

            </div>

        );

}

export default ShowContent;
