import React,{useState,useEffect} from 'react';
// import { EditorState,convertToRaw,convertFromRaw} from 'draft-js';
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import { Editor } from 'react-draft-wysiwyg';
// import "../../../assets/css/editor.css";
import CustomRequestLoader from "../customloader/CustomRequestLoader";
import useAjaxHook from "../CustomHooks/useAjaxhook";
import axios from "axios";
import $ from "jquery";
import MaterialTable from 'material-table';
import Imagemodel from "../Customcomponent/Imagemodel";
import Popup from "../customloader/Popup";
const Content =(props)=>
{
       const {storedata,updatedata,getalldata,deletedata} = useAjaxHook();
       const [msg,setMsg] = useState("");
       const [loader,setLoader] = useState(false);
       // const [editorState,setEditorState] = useState(() => EditorState.createEmpty());
       const [currentImage,setCurrentImage] = useState("");
       const [show, setShow] = useState(false);
       const [check,setCheck] = useState(false);
       const [inputData,setInputData] = useState({
        tipsTitle:"",
        tipsDescription:"",
        tipsType:"Don't",
        imageUrl:"",
        status:true,
        requestType:"POST",
        id:""
     });

       const [btn,setbtn] = useState(false);
       const [submitBtn,setSubmitbtn] = useState(true);
       const [alldata,setAlldata] = useState([]);
       const columns = [
         {title:"Image url",field:"imageUrl",render: rowData=><img src={rowData.imageUrl} alt="tipsimage" onClick={()=>{handleShow(rowData.tableData.id)}}/>},
         {title:"Tips title",field:"tipsTitle"},
         {title:"Tips type",field:"tipsType"},
         {title:"Status",field:"status",lookup:{true:'Active',false:'Deactive'}}
       ];

       useEffect(()=>{
         if(alldata.length === 0)
         {
             const url = "https://datingapi.lipsticksncufflinks.com/getAllTips";
             const ajax = getalldata(url);
             ajax.then((response)=>{
                if(response.data.status === 200)
                {
                    const data = response.data.data;
                    setAlldata(data);
                }
             });

         }
       },[alldata,getalldata]);


       const createTips=(event)=>
       {
           event.preventDefault();
           let formdata = inputData;
           const url = "https://datingapi.lipsticksncufflinks.com/createTips"
           setLoader(true);
           if(inputData.requestType === "POST")
           {
               const prepareData = {...formdata,tipsTitle:inputData.tipsTitle.toLowerCase(),tipsDescription:inputData.tipsDescription.toLowerCase()};
               const ajax = storedata(url,prepareData);
               ajax.then((response)=>{
                 if(response.data.status === 200)
                 {
                    setInputData({
                      tipsTitle:"",
                      tipsDescription:"",
                      tipsType:"Don't",
                      imageUrl:"",
                      status:true,
                      requestType:"POST",
                      id:""
                   });

                    // setEditorState(() => EditorState.createEmpty());
                    setLoader(false);
                    $(".notice").removeClass("d-none");
                    setMsg("Record created");
                    removeMsg();
                    const newData = alldata.concat(response.data.data);
                    setAlldata(newData);
                 }
                 else
                 {
                     setLoader(false);
                     $(".notice").removeClass("d-none");
                     setMsg("Something is wrong try again");
                     removeMsg();
                 }
               });

               ajax.catch((error)=>{
                 if(error)
                 {
                   setLoader(false);
                   $(".notice").removeClass("d-none");
                   setMsg("Something is wrong try again");
                   removeMsg();
                 }
               });
           }

           if(inputData.requestType === "PUT")
           {
              const id  = inputData.id;
              const prepareData = {...inputData,tipsTitle:inputData.tipsTitle.toLowerCase(),tipsDescription:inputData.tipsDescription.toLowerCase()};
              const url = "https://datingapi.lipsticksncufflinks.com/updateTipsById/"+id;
              const ajax = updatedata(url,prepareData);
              ajax.then((response)=>{
                if(response.data.status === 200)
                {
                  setInputData({
                      tipsTitle:"",
                      tipsDescription:"",
                      tipsType:"Don't",
                      imageUrl:"",
                      status:true,
                      requestType:"POST",
                      id:""
                   });
                   // setEditorState(() => EditorState.createEmpty());
                   const newData = alldata.map((item,i)=>{
                     return item._id === id ? response.data.data : item;
                   });
                   setAlldata(newData);

                   setLoader(false);
                   $(".notice").removeClass("d-none");
                   setMsg("Update Success");
                   removeMsg();
                   setCheck(false);
                   setSubmitbtn(true);
                   setbtn(false);
                }
                else
                {
                    setLoader(false);
                    $(".notice").removeClass("d-none");
                    setMsg("Something is wrong try again");
                    removeMsg();
                }
              });

              ajax.catch((error)=>{
                if(error)
                {
                  setLoader(false);
                  $(".notice").removeClass("d-none");
                  setMsg("Something is wrong try again");
                  removeMsg();
                }
              });
           }

       }

       const handleClose = () => setShow(false);
       const handleShow = (index) => {
          setShow(true);
          const url = alldata[index].imageUrl;
          setCurrentImage(url);
       }


       // const controlTextEditor=(editorState)=>
       // {
       //     setEditorState(editorState);
       //     let content = editorState.getCurrentContent();
       //     content = JSON.stringify(convertToRaw(content));
       //     setInputData({...inputData,tipsDescription:content});
       // }

        // const getFileBase64 = (file, callback) => {
        //   var reader = new FileReader();
        //   reader.readAsDataURL(file);
        //   reader.onload = () => callback(reader.result);
        //   reader.onerror = error => {};
        // };
        //
        // const imageUploadCallback = file => new Promise(
        //   (resolve, reject) => getFileBase64(
        //     file,
        //     data => resolve({ data: { link: data } })
        //   )
        // );

        const handleInput=(event)=>
        {
           const name = event.target.name;
           const val  = event.target.value;
           setInputData({...inputData,[name]:val});
        }

        const handleImage=(event)=>
        {
           if(event.target.files[0] !== undefined)
           {
             const input = event.target;
             event.persist();
             const file = event.target.files[0];
             let fname = file.name;
             let ftype = file.type;
             if(ftype === "image/jpg" || ftype === "image/png" || ftype === "image/gif" || ftype === "image/jpeg")
             {
                 setLoader(true);
                 if($(input).next().hasClass('val_notice'))
                 {
                   $(input).next().remove();
                 }

                 fname = fname.split(".")[0];
                 let formdata = new FormData();
                 formdata.append("avatar",file);
                 formdata.append("name",fname);
                 const ajax = axios({
                   method:"POST",
                   url:"https://datingmedia.lipsticksncufflinks.com/single-image",
                   data:formdata
                 });

                 ajax.then((response)=>{
                   const img_url = response.data.data.url;
                   setInputData({...inputData,imageUrl:img_url});
                   setLoader(false);
                 });

                 ajax.catch(()=>{
                   setInputData({...inputData,imageUrl:""});
                   setLoader(false);
                 });
             }

             else
             {
                 if($(input).next().hasClass('val_notice'))
                 {
                   $(input).next().remove();
                 }

                 $("<div class='d-flex my-2 align-items-center val_notice'><span class='material-icons mr-2 text-danger'>error</span><span class='text-danger'>Upload image file only</span></div>").insertAfter(input);
                 $(input).val("");

                 setInputData({...inputData,imageUrl:""});
             }
           }
           else
           {
             setInputData({...inputData,imageUrl:""});
           }

        }

        const viewTips=(rowdata)=>
        {
            const data = rowdata;
            setInputData({
                       tipsTitle:data.tipsTitle,
                       tipsDescription:data.tipsDescription,
                       tipsType:"Don't",
                       imageUrl:data.imageUrl,
                       status:true,
                       requestType:"POST",
                       id:""
                    });
                    setCheck(true);
                    setSubmitbtn(false);
                    setbtn(false);
        }

        const editingTips=(rowdata)=>
        {
          const data = rowdata;
          setInputData({
                     tipsTitle:data.tipsTitle,
                     tipsDescription:data.tipsDescription,
                     tipsType:data.tipsType,
                     imageUrl:data.imageUrl,
                     status:true,
                     requestType:"PUT",
                     id:data._id
                  });
            setCheck(false);
            setSubmitbtn(false);
            setbtn(true);
        }

        const CancelEdting=()=>
        {
            setCheck(false);
            setSubmitbtn(true);
            setbtn(false);
            setInputData({
                       tipsTitle:"",
                       tipsDescription:"",
                       tipsType:"Don't",
                       imageUrl:"",
                       status:true,
                       requestType:"PUT",
                       id:""
                    });
        }

        const deleteTips=(index)=>
        {
            const confirm = window.confirm("Do you want to delete ?");
            if(confirm)
            {
                 const obj = alldata[index];
                 const url = "https://datingapi.lipsticksncufflinks.com/deleteTipsById/"+obj._id;
                 const ajax = deletedata(url);
                 setLoader(true);
                 ajax.then((response)=>{
                   setLoader(false);
                   if(response.data.status === 200)
                   {
                       const newData = alldata.filter((item,i) => {
                          return index !== i;
                       });
                       setAlldata(newData);
                       $(".notice").removeClass("d-none");
                       setMsg("Delete Success");
                       removeMsg();
                       setInputData({
                                  tipsTitle:"",
                                  tipsDescription:"",
                                  tipsType:"Don't",
                                  imageUrl:"",
                                  status:true,
                                  requestType:"PUT",
                                  id:""
                               });
                   }
                   else
                   {
                     $(".notice").removeClass("d-none");
                     setMsg("Delete Failed");
                     removeMsg();
                   }
                 });

                 ajax.catch((error)=>{
                   if(error)
                   {
                     $(".notice").removeClass("d-none");
                     setMsg("Delete Failed");
                     removeMsg();
                   }
                 });
            }

        }


        const removeMsg=()=>
        {
            setTimeout(()=>{
              setMsg("");
              $(".notice").addClass("d-none");
            },3000);
        }

        const removeImage=(index)=>
        {
            const confirm = window.confirm("Do you want to delete ?");
            if(confirm)
            {
              setInputData({...inputData,imageUrl:""});
            }
        }



        return (
          <div className="ms-content-wrapper pr-5">
              <div className="row">
                  <div className="col-md-12">
                      <div className="ms-panel">

                          <div className="ms-panel-body px-4">
                            <h4 className="mb-4">Manage dating & tips</h4>
                            <form onSubmit={createTips}>

                              <div className="form-group">
                                <label>Tips description</label>
                                <input name="tipsDescription" onChange={handleInput} value={inputData.tipsDescription} disabled={check} className="form-control rounded-0" style={{height:"40px"}}/>
                              </div>

                              <div className="d-flex">
                                <div className="form-group w-50">
                                  <label>Tips title</label>
                                  <input type="text" className="form-control rounded-0" value={inputData.tipsTitle} name="tipsTitle" onChange={handleInput} disabled={check} required="required"/>
                                </div>

                                <div className="form-group w-50 pl-2">
                                  <label>Tips type</label>
                                  <select className="form-control rounded-0" name="tipsType" value={inputData.tipsType} onChange={handleInput} disabled={check}>
                                    <option value="Don't">Dont's</option>
                                    <option value="Do's">Do's</option>
                                  </select>
                                </div>
                              </div>

                                <div className="d-flex">

                                  <div className="form-group w-50">
                                    <label>Image url</label>
                                    <div className="custom-file">
                                      <input type="file" className="custom-file-input" id="imgurl"  name="avtar" accept="image/*" required={check} disabled={check} onChange={handleImage}/>
                                      <label htmlFor="imgurl" className="custom-file-label rounded-0">Upload image</label>
                                    </div>
                                    <div className='my-2 d-flex align-items-center'>
                                      {inputData.imageUrl !== '' && <div className='d-flex flex-column align-items-center'><img src={inputData.imageUrl} width='120' height='90' alt="tipsimage"/>
                                    <button className='btn btn-warning p-0 m-0 mt-1 rounded-0' disabled={check} type="button" onClick={(event)=>{removeImage("")}}>delete</button></div>}
                                    </div>
                                  </div>

                                  <div className="form-group w-50 pl-2">
                                    <label>Status</label>
                                    <select className="form-control rounded-0" value={inputData.status} name="status" onChange={handleInput} disabled={check}>
                                      <option value={true}>Active</option>
                                      <option value={false}>Deactive</option>
                                    </select>
                                  </div>

                                </div>

                                {submitBtn &&
                                <div className="form-group">
                                  <button type="submit" className="btn btn-danger rounded-0" disabled={check}>
                                    Add tips
                                  </button>
                                </div>
                              }

                              {btn &&
                              <div className="form-group">
                                <button type="submit" className="btn btn-info rounded-0 mr-2">
                                  Update tips
                                </button>
                                <button type="submit" className="btn btn-dark rounded-0" onClick={CancelEdting}>
                                  Cancel
                                </button>
                              </div>
                            }

                            </form>

                            <div className="mt-5">
                              <Popup msg={msg}/>
                            </div>

                            <MaterialTable
                              title="Tips content"
                              columns={columns}
                              data={alldata}
                              actions={[
                               {icon: 'visibility',tooltip: 'View Tips',onClick: (event, rowData) => {viewTips(rowData)}},
                               {icon: 'create',tooltip: 'View Tips',onClick: (event, rowData) => {editingTips(rowData)}},
                               {icon: 'delete',tooltip: 'Delete Tips',onClick: (event, rowData) => {deleteTips(rowData.tableData.id)}},
                              ]}
                              options={{
                                actionsColumnIndex: -1
                              }}
                              />


                          </div>
                      </div>
                  </div>
              </div>

              <Imagemodel
                handleShow={handleShow}
                handleClose={handleClose}
                show={show}
                currentImage={currentImage}
                />

              <div className={loader ? '' : 'd-none'}>
                <CustomRequestLoader/>
              </div>

          </div>

        );

}

export default Content;
