import React, { useEffect, useState } from 'react'
import MaterialTable from 'material-table';
import Table from "../../ownComponents/Table";
import CustomRequestLoader from "../customloader/CustomRequestLoader";
import ServiceModal from './ServiceModel';
import axios from "axios";
import Swal from 'sweetalert2';

const Services = () => {
  const headerStyle = {
    backgroundColor: '#5939B6',
    color: '#fff',
    fontSize: '14px',
    fontWeight: 'normal',
    padding: '10px',
    position: 'sticky',
    top: 0 ,
  
  }
  const rowStyle={
    // fontSize:'12px',
    // padding:'10px',
    // color:'#818698',
  }

  const URL="https://datingapi.lipsticksncufflinks.com"

  const [ResvReqName, setResvReqName] = useState([]) //Reservation 

  const [SpeclReqName, setSpeclReqName] = useState([]);//special-request

  const [editMode, setEditMode] = useState("");

  const [searchData, setSearchData] = useState([]);
  
  const [userData, setUserData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState({});

  const column = [
    { title: "Request Type", field: "type" },
    { title: "Request Name", field: "reqName" },  
  ];

  useEffect(() => {
    getAllTypes();
  }, [loader]);


  const handleClose = () => {
    setModalData({});
    setEditMode("");
    setShow(false);
    getAllTypes();
  };

  const handleShow = () => {
    setShow(true)
  };

  const getAllTypes=()=>{
    axios.get(`${URL}/getAllRequestTypes`)
      .then((response) => {
        const data = response.data.data;
        setSpeclReqName(data);
        setModalData(data);
      })
  }

//modal data
  const getUserProfile = (data, keyName) => {
    if (Object.keys(data).length!==0) {
      setModalData(data);
      setEditMode(keyName);
      handleShow();
    }
  }

//modal
  const handleModal = () => {
    handleShow();
    setModalData({});
    setEditMode("");
  }

  //delete request
  const deleteRequestSpecl = (index) => {
    const obj = SpeclReqName[index];
    Swal.fire({
      title: 'Delete Request?',
      text:"Are you sure you want to delete this request?",
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-danger rounded-0 m-2 p-1 align-items-center',
        cancelButton: 'btn btn-dark rounded-0 m-2 p-1 align-items-center',
        title:"font-weight-light text-sm"
      }
    }).then((result) => {
      if (result.isConfirmed) {
        setLoader(true)
        axios.delete("https://datingapi.lipsticksncufflinks.com/deleteReqById/" + obj._id)
        .then((result) => {
          if(result.status===200)
          {
            setSpeclReqName(result)    
            setLoader(false)
            Swal.fire('Deleted!','','success')
            getAllTypes();
          }
        })
      }
    })
    
  }

  const addLatestRecord = (data) => {
    const newAllData = ResvReqName.concat(data);
    setResvReqName(newAllData);

    const sData = searchData.concat(data);
    setSearchData(sData);
  }

  const updatedataRequest = (data) => {
    const newData = ResvReqName.map((obj) => {
      return data.userId === obj.userId ? { ...obj, ...data } : obj;
    });

    const sData = searchData.map((obj) => {
      return data.userId === obj.userId ? { ...obj, ...data } : obj;
    });

    if (userData.userId === data.userId) {
      setUserData({ ...userData, ...data });
    }
    setResvReqName(newData);
    setSearchData(sData);
  }


  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-md-12">
          <div className="ms-panel">
            <div className="ms-panel-body">
              
              <div className="card rounded-0">
                <div className="card-body">
                <div className="d-flex justify-content-between align-items-center  py-2">
                <h4 className="p-1 m-0">Services Types</h4>
                <button className="btn btn-danger rounded-0 m-0 d-flex align-items-center" onClick={handleModal}>
                  <span className="material-icons text-white mr-2" style={{ fontSize: "20px" }} >add_circle</span>Add new Request
                </button>
              </div>
                    <div className="my-4">     
                      <Table
                        search={true} 
                        paging={true}
                        pageSize={5}
                        headerStyle={headerStyle}
                        rowStyle={rowStyle}
                        columns={column}
                        data={SpeclReqName}
                        actions={[
                          { icon: 'visibility', tooltip: 'View user', onClick: (event, rowData) => { getUserProfile(rowData, 'view') } },
                          { icon: 'create', tooltip: 'Edit Text', onClick: (event, rowData) => { getUserProfile(rowData, 'edit') } },
                          { icon: 'delete', tooltip: 'Delete Text', onClick: (event, rowData) => { deleteRequestSpecl(rowData.tableData.id) } }
                        ]}
                        options={{
                          actionsColumnIndex: -1
                        }}
                      />
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div className={loader ? '' : 'd-none'}>
        <CustomRequestLoader />
      </div>

      <ServiceModal
        show={show}
        handleClose={handleClose}
        addLatestRecord={addLatestRecord}
        modalData={modalData}
        editMode={editMode}
        updatedataRequest={updatedataRequest}
      />
    </div>

  );

}

export default Services;
