import React,{useState,useEffect} from 'react';
// import { EditorState,convertToRaw,convertFromRaw} from 'draft-js';
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import { Editor } from 'react-draft-wysiwyg';
// import "../../../assets/css/editor.css";
import CustomRequestLoader from "../customloader/CustomRequestLoader";
import MaterialTable from 'material-table';
import useAjaxHook from "../CustomHooks/useAjaxhook";
import Imagemodel from "../Customcomponent/Imagemodel";
import axios from "axios";
import $ from "jquery";
import Popup from "../customloader/Popup";

const Content =(props)=>
{
       const {storedata,updatedata,getalldata,deletedata} = useAjaxHook();
       const [msg,setMsg] = useState("");
       const [loader,setLoader] = useState(false);
       // const [editorState,setEditorState] = useState(() => EditorState.createEmpty());
       const [check,setCheck] = useState(false);
       const [SubmitBtn,setSubmitBtn] = useState(true);
       const [btn,setbtn] = useState(false);

       const [inputData,setInputData] = useState({
         quotesTitle:"",
         quotesDescription:"",
         author:"",
         imageUrl:"",
         requestType:"POST",
         status:true,
         id:""
       });

       const [alldata,setAlldata] = useState([]);
       const [currentImage,setCurrentImage] = useState("");
       const [show, setShow] = useState(false);
       const columns = [
         {title: 'Image',field:"imageUrl",render: rowData => <img src={rowData.imageUrl} alt="quotesimage"  onClick={()=>{handleShow(rowData.tableData.id)}}/>},
         {title:"Quotes title",field:"quotesTitle"},
         {title:"Author",field:"author"},
         {title:"Status",field:"status",lookup:{true:'Active',false:'Deactive'}}
       ];

       useEffect(()=>{
         if(alldata.length === 0)
         {
             const url = "https://datingapi.lipsticksncufflinks.com/getAllQuotes";
             const ajax = getalldata(url);
             ajax.then((response)=>{
                if(response.data.status === 200)
                {
                    const data = response.data.quotes;
                    setAlldata(data);
                }
             });

         }
       },[alldata,getalldata]);



       const createQuotesContent=(event)=>
       {
           event.preventDefault();
           let formdata = inputData;
           const url = "https://datingapi.lipsticksncufflinks.com/createQuotes"

           if(inputData.requestType === "POST")
           {
             setLoader(true);
             const ajax = storedata(url,formdata);
             ajax.then((response)=>{
               if(response.data.status === 200)
               {
                  setInputData({
                     quotesTitle:"",
                     quotesDescription:"",
                     author:"",
                     imageUrl:"",
                     requestType:"POST",
                     status:true,
                     id:""
                   });
                  setLoader(false);
                  $(".notice").removeClass("d-none");
                  setMsg("Record created");
                  removeMsg();
                  setbtn(false);
                  setCheck(false);
                  setSubmitBtn(true);
                  const newData = alldata.concat(response.data.data);
                  setAlldata(newData);
               }
               else
               {
                   setLoader(false);
               }
             });

             ajax.catch((error)=>{
               if(error)
               {
                  setLoader(false);
                  $(".notice").removeClass("d-none");
                  setMsg("Something is wrong try again");
                  removeMsg();
               }
             });
           }

           if(inputData.requestType === "PUT")
           {
              setLoader(true);
              const id  = inputData.id;
              const url = "https://datingapi.lipsticksncufflinks.com/updateQuoteById/"+id;
              const ajax = updatedata(url,inputData);
              ajax.then((response)=>{
                if(response.data.status === 200)
                {
                  setInputData({
                      quotesTitle:"",
                      quotesDescription:"",
                      author:"",
                      imageUrl:"",
                      requestType:"POST",
                      status:true,
                      id:""
                    });
                   setLoader(false);
                   $(".notice").removeClass("d-none");
                   setMsg("Update Success");
                   removeMsg();
                   setbtn(false);
                   setCheck(false);
                   setSubmitBtn(true);

                   const newData = alldata.map((item,i)=>{
                      return item._id === id ? response.data.quotes : item;
                   });

                   setAlldata(newData);

                }
                else
                {
                    setLoader(false);
                    $(".notice").removeClass("d-none");
                    setMsg("Something went wrong try again");
                    removeMsg();
                }
              });

              ajax.catch((error)=>{
                if(error)
                {
                  $(".notice").removeClass("d-none");
                  setMsg("Something went wrong try again");
                  removeMsg();
                }
              });

           }

       }




        const handleInput=(event)=>
        {
           const name = event.target.name;
           const val  = event.target.value;
           setInputData({...inputData,[name]:val});
        }

        const handleImage=(event)=>
        {
           if(event.target.files[0] !== undefined)
           {

             const input = event.target;
             event.persist();
             const file = event.target.files[0];
             let fname = file.name;
             let ftype = file.type;
             if(ftype === "image/jpg" || ftype === "image/png" || ftype === "image/gif" || ftype === "image/jpeg")
             {
                 setLoader(true);
                 if($(input).next().hasClass('val_notice'))
                 {
                   $(input).next().remove();
                 }

                 fname = fname.split(".")[0];
                 let formdata = new FormData();
                 formdata.append("avatar",file);
                 formdata.append("name",fname);
                 const ajax = axios({
                   method:"POST",
                   url:"https://datingmedia.lipsticksncufflinks.com/single-image",
                   data:formdata
                 });

                 ajax.then((response)=>{
                   const img_url = response.data.data.url;
                   setInputData({...inputData,imageUrl:img_url});
                   setLoader(false);
                 });

                 ajax.catch((error)=>{
                   if(error)
                   {
                     setInputData({...inputData,imageUrl:""});
                     setLoader(false);
                   }
                 });
             }

             else
             {
                 if($(input).next().hasClass('val_notice'))
                 {
                   $(input).next().remove();
                 }

                 $("<div class='d-flex my-2 align-items-center val_notice'><span class='material-icons mr-2 text-danger'>error</span><span class='text-danger'>Upload image file only</span></div>").insertAfter(input);
                 $(input).val("");

                 setInputData({...inputData,imageUrl:""});
             }
           }
           else
           {
             setInputData({...inputData,imageUrl:""});
           }

        }

        const removeMsg=()=>
        {
            setTimeout(()=>{
              setMsg("");
              $(".notice").addClass("d-none");
            },3000);
        }

        const removeImage=()=>
        {
          const confirm = window.confirm("Do you want to delete ?");
          if(confirm)
          {
            setInputData({...inputData,imageUrl:""});
          }
        }

        const handleClose = () => setShow(false);
        const handleShow = (index) => {
           setShow(true);
           const url = alldata[index].imageUrl;
           setCurrentImage(url);
        }

        const deleteQuotes=(index)=>
        {
            const confirm = window.confirm("Do you want to delete ?");
            if(confirm)
            {
                 const obj = alldata[index];
                 const url = "https://datingapi.lipsticksncufflinks.com/deleteQuoteById/"+obj._id;
                 const ajax = deletedata(url);
                 setLoader(true);
                 ajax.then((response)=>{
                   setLoader(false);
                   if(response.data.status === 200)
                   {
                       $(".notice").removeClass("d-none");
                       setMsg("Delete Success");
                       removeMsg();
                       setbtn(false);
                       setCheck(false);
                       setSubmitBtn(true);
                       const newData = alldata.filter((item,i) => {
                         return index !== i;
                       });
                       setAlldata(newData);
                       setInputData({
                         quotesTitle:"",
                         quotesDescription:"",
                         author:"",
                         imageUrl:"",
                         requestType:"POST",
                         status:true,
                         id:""
                       });
                   }
                 });

                 ajax.catch((error)=>{
                   if(error)
                   {
                     setLoader(false);
                     $(".notice").removeClass("d-none");
                     setMsg("Delete Failed");
                     removeMsg();
                     setbtn(false);
                     setCheck(false);
                     setSubmitBtn(true);
                   }
                 });
            }

        }

        const viewQuotesData=(rowdata)=>
        {
            const index = rowdata.tableData.id;
            const data  = alldata[index];
            setInputData({
              quotesTitle:data.quotesTitle,
              quotesDescription:data.quotesDescription,
              author:data.author,
              imageUrl:data.imageUrl,
              requestType:"POST",
              status:true,
              id:""
            });
            setCheck(true);
            setSubmitBtn(false);
        }

        const cancelQuotes=()=>
        {
            setInputData({
              quotesTitle:"",
              quotesDescription:"",
              author:"",
              imageUrl:"",
              requestType:"POST",
              status:true,
              id:""
            });
            setCheck(false);
            setSubmitBtn(true);
            setbtn(false);
        }

        const EditingQuotes=(index)=>
        {
            const data  = alldata[index];
            setInputData({
              quotesTitle:data.quotesTitle,
              quotesDescription:data.quotesDescription,
              author:data.author,
              imageUrl:data.imageUrl,
              requestType:"PUT",
              status:true,
              id:data._id
            });
            setCheck(false);
            setSubmitBtn(false);
            setbtn(true);
        }

        return (
          <div className="ms-content-wrapper pr-5">
              <div className="row">

                  <div className="col-md-12">
                      <div className="ms-panel">
                          <div className="ms-panel-body px-4">
                            <h4 className="mb-4">Manage quotes</h4>
                            <form onSubmit={createQuotesContent}>

                              <div className="form-group">
                                <label>Quotes Description</label>
                                <input name="quotesDescription" value={inputData.quotesDescription} onChange={handleInput} className="form-control rounded-0" style={{height:"60px"}} disabled={check} />
                              </div>

                          <div className="d-flex">

                              <div className="form-group w-50">
                                <label>Quotes title</label>
                                <input type="text" className="form-control rounded-0" value={inputData.quotesTitle} name="quotesTitle" onChange={handleInput} disabled={check} required="required"/>
                              </div>

                              <div className="form-group w-50 pl-2">
                                <label>Image url</label>
                                <div className="custom-file">
                                  <input type="file" className="custom-file-input" id="imgurl"  name="avtar" accept="image/*" disabled={check} required={check} onChange={handleImage}/>
                                  <label htmlFor="imgurl" className="custom-file-label rounded-0">Upload image</label>
                                </div>
                                <div className='my-2 d-flex align-items-center'>
                                  {inputData.imageUrl !== '' && <div className='d-flex flex-column align-items-center'><img src={inputData.imageUrl} alt="quotesimage"  width='120' height='90' alt="content"/>
                                <button className="btn btn-warning p-0 m-0 rounded-0 mt-2" type="button" disabled={check} onClick={(event)=>{removeImage()}}>
                                  delete</button>
                                </div>}

                                </div>
                              </div>

                          </div>

                          <div className="d-flex">

                              <div className="form-group w-50">
                                <label>Author Name</label>
                                <input type="text" className="form-control rounded-0" value={inputData.author}  name="author" onChange={handleInput} disabled={check} required="required"/>
                              </div>

                              <div className="form-group w-50 pl-2">
                                <label>Status</label>
                                <select className="form-control rounded-0" value={inputData.status} name="status" onChange={handleInput} disabled={check}>
                                  <option value={true}>Active</option>
                                  <option value={false}>Deactive</option>
                                </select>
                              </div>

                            </div>

                            <div className="form-group">
                              {SubmitBtn &&
                              <button type="submit" className="btn btn-danger rounded-0" disabled={check}>
                                Add quotes
                              </button>
                             }

                             {btn &&
                             <div>
                               <button type="submit" className="btn btn-info rounded-0 mr-2">
                                 Update quotes
                               </button>

                               <button type="button" className="btn btn-dark rounded-0" onClick={cancelQuotes}>
                                 Cancel
                               </button>
                             </div>
                            }

                            </div>

                            </form>

                            <div className="mt-5">
                              <Popup msg={msg}/>
                            </div>


                            <div className="my-5">
                              <MaterialTable
                                title="Quotes content"
                                columns={columns}
                                data={alldata}
                                actions={[
                                  {icon: 'visibility',tooltip: 'View Text',onClick: (event, rowData) => {viewQuotesData(rowData)}},
                                  {icon: 'create',tooltip: 'Delete Text',onClick: (event, rowData) => {EditingQuotes(rowData.tableData.id)}},
                                  {icon: 'delete',tooltip: 'Delete Text',onClick: (event, rowData) => {deleteQuotes(rowData.tableData.id)}}
                                ]}
                                options={{
                                  actionsColumnIndex: -1
                                }}
                                />
                            </div>

                          </div>
                      </div>
                  </div>
              </div>

              <div className={loader ? '' : 'd-none'}>
                <CustomRequestLoader/>
              </div>

              <Imagemodel
                handleShow={handleShow}
                handleClose={handleClose}
                show={show}
                currentImage={currentImage}
                />

          </div>

        );

}

export default Content;
