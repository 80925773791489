import axios from 'axios';
export const LOGIN_BEGIN = 'LOGIN_BEGIN'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const RESET_ERROR = 'RESET_ERROR'


const APIURL = "https://datingapi.lipsticksncufflinks.com/users/login";

export const login = (email, password,fireBaseToken) => {
    return dispatch => {
        dispatch({
            type: LOGIN_BEGIN
        })

        axios
            .post(APIURL, {
                "username": email, "password": password, "userAuthTypes": "stepout",fireBaseToken:fireBaseToken
            })
            .then(res => {
                if (res.data.user_token && res.data.user_token.role == 'admin') {
                    axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.user_token.accessToken}`;
                    dispatch({
                        type: LOGIN_SUCCESS,
                        payload: res.data.user_token
                    })
                } else if (res.data.message) {
                    dispatch({
                        type: LOGIN_FAIL,
                        payload: res.data.message
                    })
                }
            })
            .catch(err => {
                dispatch({
                    type: LOGIN_FAIL
                })
            });
    };
};

export const logout = { type: LOGOUT_SUCCESS }
export const resetError = { type: RESET_ERROR }
