import React,{useState,useEffect} from 'react';
import useAjaxhook from "../CustomHooks/useAjaxhook";
import {Link} from "react-router-dom";

const Quotes=()=>
{

  const {getalldata} = useAjaxhook();

  useEffect(()=>{
    getQuotes();
  },[]);

  const [quotesCount,setQuotesCount] = useState(0);


  const getQuotes=()=>
  {
    const url = "https://datingapi.lipsticksncufflinks.com/getAllQuotes";
    const ajax = getalldata(url);
    ajax.then((response)=>{
       if(response.data.status === 200)
       {
           const data = response.data.quotes;
           setQuotesCount(data);
       }
    });
  }

  return(
    <div className="col-md-4 mb-5">
        <div className="card rounded-0 border-0 shadow-lg py-3">
        <div className="card-body container_box pr-0">
          <div className="dashboard_icon mb-3">
            <div className="d-flex">
              <span className="material-icons qus_icon">
                 format_quote
               </span>
           </div>
          </div>

          <div>
            <h6 className="text-center float-right border p-3">
              <Link to='/add-quotes-content'>Quotes</Link>
            </h6>
          </div>


        </div>
      </div>
    </div>
  )
}

export default Quotes;
