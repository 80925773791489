import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { login,resetError } from '../../../redux/action/authAction'
import costiclogo from '../../../assets/img/lc.png';
import logoWithoutTagline from '../../../assets/img/logo-without-tagline.png';
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import axios from "axios";
import $ from "jquery";
import "animate.css";


const Content = (props) => {

    const [forgotpassword,setforgotpassword] = useState([]);
    const [forgotMsg,setforgotMsg] = useState("");

    // eslint-disable-next-line no-unused-expressions
    const auth = useSelector(state => state.user);
    const dispatch = useDispatch();

    const [data, setData] = useState({
        forgotPassword: false,
        email: "",
        password: "",
        error: false,
        fireBaseToken:"",
        showPassword: false,

    });

    useEffect(()=>{
      let ftoken = sessionStorage.getItem("ftoken");
      setData({...data,fireBaseToken:ftoken});
    },[]);



    const handleChnage = (e) => {
        e.persist();
        setData(prev => ({ ...prev, [e.target.id]: e.target.value }));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(login(data.email, data.password,data.fireBaseToken));
    }

    const closeModal = () => {
        setData(prev => ({ ...prev, forgotPassword: false, error: false }));
        dispatch(resetError);
    }

    const showModal = (e) => {
        setData(prev => ({ ...prev, forgotPassword: true }));
    }

    useEffect(() => {
        if (auth.error === "Incorrect username or password" && !auth.isLoggedIn) {

            setData(prev => ({ ...prev, error: true }));
        }
        else if (auth.error === "login error"){
            setData(prev => ({ ...prev, error: true }));
        }
    }, [auth.error, auth.isLoggedIn]);

    const resetPassword=(event)=>
    {
       event.preventDefault();

       const ajax = axios({
         method:"POST",
         url:"https://datingapi.lipsticksncufflinks.com/users/forgotPassword",
         data:{
           email:forgotpassword
         }
       });

       ajax.then((response)=>{
         if(response.data.status === 200)
         {
             $(".forgotError").addClass("animate__animated animate__zoomInDown");
             setforgotMsg("We have sent a link to your email click on that link");

             setTimeout(()=>{
               $(".forgotError").removeClass("animate__animated animate__zoomInDown");
               setforgotMsg("");
             },5000);
         }

         if(response.data.status === 400)
         {
           $(".forgotError").addClass("animate__animated animate__zoomInDown");
           setforgotMsg("User Not Registerd With Us");

           setTimeout(()=>{
             $(".forgotError").removeClass("animate__animated animate__zoomInDown");
             setforgotMsg("");

           },5000);

         }
       });

       ajax.catch((error)=>{
         // console.log(error);
       });
    }

    const handleClickShowPassword = () => {
        setData({ ...data, showPassword: !data.showPassword });
      }
    
      const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };

    if (auth.user_details.accessToken && auth.isLoggedIn) return (<Redirect to='/dashboard' />);
    return (
        <div className="ms-content-wrapper ms-auth">
            <div className="ms-auth-container">
                <div className="ms-auth-col">
                    <div className="ms-auth-form">
                        <form className="needs-validation" onSubmit={(e) => { handleSubmit(e) }} noValidate>
                          <div className="d-flex justify-content-center">
                            <img src={costiclogo} alt="logo"/>
                          </div>

                            <h4 className="text-center">Login to Account</h4>
                            <p className="text-center">Please enter your email and password to continue</p>
                            <div className="mb-3">
                                <label htmlFor="validationCustom08">Email Address</label>
                                <div className="input-group">
                                    <input type="email" className="form-control" id="email" placeholder="Email Address" onChange={(e) => { handleChnage(e) }} required />
                                    <div className="invalid-feedback">Please provide a valid email.</div>
                                </div>
                            </div>
                            <div className="mb-2">
                                <label htmlFor="validationCustom09">Password</label>
                                <div className="input-group">
                                    <Input type={data.showPassword ? "text" : "password"} disableUnderline={true} className="form-control" id="password" placeholder="Password" onChange={(e) => { handleChnage(e) }} required
                                         endAdornment={
                                            <InputAdornment position="end">
                                              <IconButton
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                              >
                                                {data.showPassword ? <Visibility /> : <VisibilityOff />}
                                              </IconButton>
                                            </InputAdornment>
                                          }
                                    />
                                    <div className="invalid-feedback">Please provide a password.</div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="ms-checkbox-wrap">
                                    <input className="form-check-input" type="checkbox" defaultValue /> <i className="ms-checkbox-check" />
                                </label> <span> Remember Password </span>
                                <label className="d-block mt-3"><Link to="/" onClick={showModal} className="btn-link">Forgot Password?</Link>
                                </label>
                            </div>
                            <button className="btn btn-danger mt-4 d-block m-auto" type="submit">Sign In</button>

                        </form>
                    </div>
                </div>
            </div>

            <Modal show={data.forgotPassword} onHide={closeModal} id="forgotPassword" className="modal-min forgotpasswordModal" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body className="text-center">
                    <button type="button" className="close" id="forgotPassword" onClick={closeModal}><span aria-hidden="true">×</span></button>
                    <i className="flaticon-secure-shield d-block" />
                    <h1>Forgot Password?</h1>
                    <p>Enter your email to recover your password</p>

                    <form onSubmit={resetPassword}>
                        <div className="ms-form-group has-icon">
                            <input type="text" placeholder="Email Address" className="form-control" name="forgot-password" value={forgotpassword} onChange={(e)=>{setforgotpassword(e.target.value)}} />
                            <i className="material-icons">email</i>
                        </div>
                        <p className="forgotError">{forgotMsg}</p>
                        <button type="submit" className="btn btn-danger shadow-none">Reset Password</button>
                    </form>

                </Modal.Body>
            </Modal>


            <Modal show={data.error} onHide={closeModal} id="error" className="modal-min" aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body className="text-center">
                    <button type="button" className="close" onClick={closeModal}><span aria-hidden="true">×</span></button>
                    <i className="fa fa-ban d-block" />
                    <h1>Error</h1>
                    <p>Incorrect Username Or Password</p>
                </Modal.Body>
            </Modal>
        </div>

    );

}

export default Content;
