import React,{useEffect} from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Preloader from './components/layouts/Preloader';
// import Dashboard from './components/pages/Dashboard';
import Admindashboard from './components/pages/Admindashboard';
import Defaultlogin from './components/pages/Defaultlogin';
import ResetPassword from './components/pages/ResetPassword';
import Error from './components/pages/Error';
import Modallogin from './components/pages/Modallogin';
import Modalregister from './components/pages/Modalregister';
import Userprofile from './components/pages/Userprofile';

import Apptextcontent from './components/pages/Apptextcontent';

import ShowAppvideocontent from './components/pages/ShowAppvideocontent';

import Quotescontent from './components/pages/Quotescontent';

import Tipscontent from './components/pages/Tipscontent';

import BasicUserContent from './components/pages/BasicUserContent';

import Questioncontent from './components/pages/Questioncontent';
import ShowQuestioncontent from './components/pages/ShowQuestioncontent';

import AgentListContent from './components/pages/AgentListContent';

import ChatContent from './components/pages/ChatContent';
import BookingPage from './components/pages/BookingPage';
import CalendarEventPage from './components/pages/CalendarEventPage';

import Services from './components/pages/Services';
import ServicesMaster from './components/pages/ServicesMaster';

import InvestmentForm from './components/pages/InvestmentForm';
import InvestmentList from './components/pages/InvestmentList';

import MemberProfileQus from './components/pages/MemberProfileQus';


import { store } from './redux/store';
import axios from 'axios';

import firebase from "./firebase";

function App()
{

  // useEffect(()=>{
  //   const messaging = firebase.messaging();
  //   messaging.requestPermission().then(()=>{
  //     return messaging.getToken();
  //   }).then((token)=>{
  //     sessionStorage.setItem("ftoken",token);
  //   }).catch((error)=>{
  //     console.log("error",error)
  //   });
  // },[]);

  useEffect(()=>{
    if ('Notification' in window)
    {
    const messaging = firebase.messaging();
    Promise.resolve(messaging.requestPermission().then(()=>{
      return messaging.getToken();
    })).then((token)=>{
      console.log(token,"token");
      sessionStorage.setItem("ftoken",token);
    }).catch((error)=>{
      console.log("error",error)
    });
  }
  },[]);


  (function () {
    const state = store.getState();
    if (state.user.user_details.accessToken) {
      const token = state.user.user_details.accessToken;
      if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      }
    }
  })();
  return (
    <Router>
      <Preloader />
      <Switch>

        <Route exact path="/basic-users-details/" component={BasicUserContent} />
        <Route exact path="/add-text-content/" component={Apptextcontent} />
        <Route exact path="/show-video-content" component={ShowAppvideocontent} />
        <Route exact path="/add-quotes-content/" component={Quotescontent} />
        <Route exact path="/add-tips-content/" component={Tipscontent} />
        <Route exact path="/add-new-question/" component={Questioncontent} />
        <Route exact path="/questions-list/" component={ShowQuestioncontent} />

        <Route exact path="/services/" component={Services} />
        <Route exact path="/servicesMaster/" component={ServicesMaster} />

        <Route exact path="/agent-list/" component={AgentListContent} />


        <Route exact path="/" component={Defaultlogin} />

        <Route path="/ResetPassword" component={ResetPassword} />

        <Route path="/dashboard" component={Admindashboard} />
        <Route path="/error" component={Error} />
        <Route path="/modal-login" component={Modallogin} /> 

       <Route path="/chat" component={ChatContent} />
       <Route path="/ticket-booking" component={BookingPage} /> 

       <Route path="/admin-event" component={CalendarEventPage} />

       <Route exact path="/admin-investment" component={InvestmentForm}/>
       <Route exact path="/admin-investment-list" component={InvestmentList}/>
       <Route exact path="/admin-memberprofile-ques" component={MemberProfileQus}/>



      </Switch>
    </Router>
  );
}

export default App;
