import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import $ from "jquery";
import useAjaxhook from "../CustomHooks/useAjaxhook";
import CustomRequestLoader from "../customloader/CustomRequestLoader";
import axios from "axios";
import Select from "react-select"
import Swal from 'sweetalert2';


const ServiceModel = (props) => {
    const URL="https://datingapi.lipsticksncufflinks.com"
    const ref = useRef();
    const { storedata, updatedata } = useAjaxhook();
    const { show, handleClose, modalData, editMode,updatedataRequest ,addLatestRecord } = props;
    const [loader, setLoader] = useState(false);
    const [check, setCheck] = useState(false);
    const [btn, setBtn] = useState(false);
    const [submitBtn, setSubmitBtn] = useState(true);
    const [reqOptions,setReqOptions] = useState([]);
    const [selValue,setSelValue] = useState('');
   
    const [service, setService] = useState({
        "type": "",
        "reqName": "",
        "status": true,
        "requestType": "POST",
        "id":""

    });

    const clearFields =()=>{
        setSelValue("");
        setService({type: "",reqName: ""})
    }


    const getDropdownValues = () =>{
        axios.get(`${URL}/getTypesForDropDown`).then(res=>{
            let data=res.data.data;
            let options=[];
            if(res.status===200){
                data&&data.map(opt=>{
                    options.push({value:opt,label:opt})
                })
                setReqOptions(options);
            }
        })
    }

    const changeHandler = (e) =>{
        setSelValue(e);
        setService({...service, type: e.value });
        console.log(ref)
        ref.current.blur();
    }

    const handleInput = (e) => {
        e.persist();
        setService({...service, [e.target.name]: e.target.value });
    }

    //get data request name
    useEffect(() => {
        getDropdownValues();
        if (editMode !== "") 
        {
            if(editMode === "view"){
                setService({
                    "type": modalData.type,
                    "reqName": modalData.reqName,
                });
                setCheck(true);
                setSubmitBtn(false);
                setBtn(false);
            }
            

            if (editMode === "edit") {
                setService({
                    "type": modalData.type,
                    "reqName": modalData.reqName,
                });
                setCheck(false);
                setSubmitBtn(false);
                setBtn(true);
            }
        }

        else {
            setService({
                "type": "",
                "reqName": "",
                "status": true,
                "requestType": "POST",
                "id":""
            });
            setCheck(false);
            setSubmitBtn(true);
            setBtn(false);
        }
    }, [editMode, modalData])

    //edit and create request type and name
    const createService = (event,index) => {
        event.preventDefault();
        if(service.requestType === "POST"){
            setLoader(true)
            axios.post(`${URL}/createRequestTypes`, service)
            .then(async (result) => {
                if(result.status===200)
                {
                    clearFields();
                    handleClose();
                    setLoader(false)  
                    Swal.fire("Request Type Added","","success");
                }
                else
                {
                    setLoader(false)
                    Swal.fire("Somethig went wrong...","","error")
                }
            })
            .catch((error) => {
                console.log(error)
                setLoader(false);
                setSelValue('')
            })

        }else{
            setLoader(true)
            axios.put(`${URL}/updateReqById/${modalData._id}`, service)
            .then(async (result) => {
                // setService(result.data)
                if(result.status===200)
                {
                    clearFields();
                    handleClose();
                    setLoader(false)
                    Swal.fire("Request Type Updated","","success");
                }
                else
                {
                    setLoader(false)
                    Swal.fire("Somethig went wrong...","","error")
                }
            })
            .catch((error) => {
                console.log(error)
                setLoader(false);
                setSelValue('')
                // handleClose();
            })
        }
    }

    

    return (
        <div className="service_modal">
            <Modal show={show} onHide={()=>{handleClose();setSelValue('');setService({type:"",reqName:""})}} size="lg">

                <Modal.Header closeButton>
                    <Modal.Title>Service </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <form onSubmit={createService}>
                        <div className="d-flex">
                            <div className="w-50">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="material-icons input-group-text rounded-0 border-right-0 bg-primary text-white">settings_suggest</span>
                                    </div>
                                    {editMode==="view"?(<input type="text" className="form-control rounded-0"
                                        name="type" required="required"  value = {service.type} onChange={handleInput} disabled={check} placeholder="Service Request" />)
                                        :(<Select
                                            ref={ref}
                                            name='type'
                                            required
                                            isDisabled={check}
                                            className="w-75 rounded-0"
                                            value={editMode==="edit"?({label:service.type,value:service.type}):selValue}
                                            // defaultValue = {{label:service.type,value:service.type}}
                                            options={reqOptions}
                                            onChange={changeHandler}
                                        />)}
                                </div>
                            </div>

                            <div className="w-50 pl-2">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="material-icons input-group-text rounded-0 border-right-0 bg-primary text-white">miscellaneous_services</span>
                                    </div>
                                    <input type="text" className="form-control rounded-0"
                                        name="reqName" required="required" value = {service.reqName} onChange={handleInput} disabled={check} placeholder="Request Name" />
                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-end">

                        {submitBtn &&
                            <button className="btn btn-danger rounded-0 notice_btn mt-0" type="submit">
                                <span className="material-icons mr-2"
                                    style={{ fontSize: "20px", color: "white" }}>person_add_alt</span>
                                  Add Service
                                </button>
                        }


                        {btn &&
                            <button className="btn btn-danger rounded-0 notice_btn mt-0" type="submit">
                                <span className="material-icons mr-2"
                                    style={{ fontSize: "20px", color: "white" }}>person_add_alt</span>
                                  update Service
                                </button>
                        }


                        </div>
                    </form>
                    
                </Modal.Body>

            </Modal>

            <div className={loader ? '' : 'd-none'}>
                <CustomRequestLoader />
            </div>
        </div>
    )
}

export default ServiceModel;
