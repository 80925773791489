import React, { Component, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom'
import $ from 'jquery';
import { Dropdown, NavLink } from 'react-bootstrap';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { logout } from '../../redux/action/authAction';

import { useDispatch, useSelector } from 'react-redux';
import logoWithoutTagline from '../../assets/img/logo-without-tagline.png';

import costiclogo from '../../assets/img/lc.png';

const Topnavigation = () => {

    const user = useSelector(state => state.user);
    const dispatch = useDispatch();

    const handleLogout = () => {
        dispatch(logout);
    }

    useEffect(() => {
        if (!user.isLoggedIn) {
            return <Redirect to="/" />
        }
    }, [user.error, user.isLoggedIn]);

    const addsidenavigation = () => {
        $('.ms-body').toggleClass('ms-aside-left-open');
        $('#ms-side-nav').toggleClass('ms-aside-open');
        $(".ms-aside-overlay.ms-overlay-left").toggleClass('d-block');
    }
    const topbaropen = () => {
        $('#ms-nav-options').toggleClass('ms-slide-down');
    }


    if (!user.user_details.accessToken && !user.isLoggedIn) return (<Redirect to='/' />);

    return (

        <nav className="navbar ms-navbar">
            <div className="ms-aside-toggler ms-toggler" onClick={addsidenavigation}>
                <span className="ms-toggler-bar bg-primary" />
                <span className="ms-toggler-bar bg-primary" />
                <span className="ms-toggler-bar bg-primary" />
            </div>
            <div className="logo-sn logo-sm ms-d-block-sm">
                <Link className="pl-0 ml-0 text-center navbar-brand mr-0" to="/"><img src={costiclogo} alt="logo" /> </Link>
            </div>
            <ul className="ms-nav-list ms-inline mb-0" id="ms-nav-options">



                <li className="ms-nav-item ms-nav-user dropdown">
                    <Dropdown className="custom-dropdown">
                        <Dropdown.Toggle as={NavLink} id="userDropdown" className="p-0">
                            <i className="fas fa-user-circle mr-2" style={{fontSize:"22px"}}></i>
                            {/* <img className="ms-user-img ms-img-round" src="assets/img/costic/customer-6.jpg" alt="people" /> */}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu dropdown-menu-right user-dropdown" aria-labelledby="userDropdown">
                            <div className="dropdown-menu-header">
                                <h6 className="dropdown-header ms-inline m-0"><span className="text-disabled">Welcome </span>{user.user_details.user_name}</h6>
                            </div>
                            <div className="dropdown-divider" />
                            <div className="ms-dropdown-list">
                                <Link className="media fs-14 p-2" to="/profileSetting"> <span><i className="flaticon-user mr-2" /> Profile</span>
                                </Link>

                                <Link className="media fs-14 p-2" to="/user-profiles"> <span><i className="flaticon-gear mr-2" /> Account Settings</span>
                                </Link>
                            </div>
                            <div className="dropdown-divider" />
                            <div className="dropdown-menu-footer px-2" >
                              <span onClick={handleLogout} style={{cursor:"pointer"}}><i className="flaticon-shut-down mr-2" />Logout</span>
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                </li>
            </ul>
            <div className="ms-toggler ms-d-block-sm pr-0 ms-nav-toggler" onClick={topbaropen}>
                <span className="ms-toggler-bar bg-primary" />
                <span className="ms-toggler-bar bg-primary" />
                <span className="ms-toggler-bar bg-primary" />
            </div>
        </nav >
    );

}

export default Topnavigation;
