import React,{useEffect,useState} from 'react';
// import {Link} from "react-router-dom";
import useAjaxHook from "../CustomHooks/useAjaxhook";
import MaterialTable from 'material-table';
// import Content from "./Content";
import CustomRequestLoader from "../customloader/CustomRequestLoader";
import CustomPageloader from "../customloader/CustomPageloader";
import $ from "jquery";
import Popup from "../customloader/Popup";


const ShowContent =()=>
{
     const [alldata,setAlldata] = useState([]);
     const [videoUrl,setVideoUrl] = useState();
     const [msg,setMsg] = useState("");
     const {getalldata,deletedata,storedata,updatedata} = useAjaxHook();
     const [loader,setLoader] = useState(false);
     const [pageLoader,setPageLoader] = useState(false);

     const [inputData,setInputData] = useState({
       videoTag:"",
       videoUrl:"",
       version:"",
       status:true,
       requestType:"POST",
     });

     const columns = [
       {title:"Video tag",field:"videoTag"},
       {title:"Video url",field:"videoUrl"},
       {title:"Status",field:"status",lookup:{true:"Active",false:"Deactive"}}
     ];

      useEffect(()=>{
        if(alldata.length === 0)
        {

            const url  = "https://datingapi.lipsticksncufflinks.com/getAllVideo";
            const ajax = getalldata(url);
            ajax.then((response)=>{
              setPageLoader(true);
              if(response.data.status === 200)
              {
                const data = response.data.SplashVideo;
                setAlldata(data);
                setVideoUrl(data[0].videoUrl);
              }
            });

            ajax.catch(()=>{
              setPageLoader(true);
            });

        }
      },[alldata,getalldata]);

      // const getVideo=(index)=>
      // {
      //    const v_url = alldata[index].videoUrl;
      //    setVideoUrl(v_url);
      // }

      const createAppVideocontent=(event)=>
      {

        event.preventDefault();
        const url = "https://datingapi.lipsticksncufflinks.com/createVideo/";
        const ajax = storedata(url,inputData);
        setLoader(true);
        ajax.then((response)=>{
          const data = response.data.SplashVideo;
          if(response.data.status === 200)
          {
             setInputData({
               videoTag:"",
               videoUrl:"",
               version:"",
               status:true
             });
             setLoader(false);
             $(".notice").removeClass("d-none");
             setMsg("Record created");
             removeMsg();
             setAlldata([data]);
             setVideoUrl(data.videoUrl);
          }
      });

    }

      const handleInput=(event)=>
      {
         const name = event.target.name;
         const val  = event.target.value;
         setInputData({...inputData,[name]:val});
      }

      const deleteData=(index)=>
      {
         const confirm = window.confirm("Do you want to delete ?");
         if(confirm)
         {
             const obj = alldata[index];
             const url = "https://datingapi.lipsticksncufflinks.com/deleteVideo/"+obj._id;
             const ajax = deletedata(url);
             setLoader(true);
             ajax.then((response)=>{
               if(response.data.status === 200)
               {
                  setLoader(false);
                  setAlldata([]);
               }
               else
               {
                 setLoader(false);
               }
             });

             ajax.catch((error)=>{
               if(error)
               {
                 setLoader(false);
               }
             });
         }
      }

      const removeMsg=()=>
      {
          setTimeout(()=>{
            setMsg("");
            $(".notice").addClass("d-none");
          },3000);
      }

      const updateVideo=(newData,oldData,resolve,reject,index)=>
      {
          const link = "https://datingapi.lipsticksncufflinks.com/updateVideo/"+oldData._id;
          const ajax = updatedata(link,newData);
          ajax.then((response)=>{
            if(response.data.status === 200)
            {
                setLoader(false);
                setInputData({
                  videoTag:"",
                  videoUrl:"",
                  version:"",
                  status:true,
                  requestType:"POST",
                });
                $(".notice").removeClass("d-none");
                setMsg("Update success");
                removeMsg();
                const update = [...alldata];
                update[index] = newData;
                setAlldata(update);
                resolve();
            }
            else
            {
               setLoader(false);
               $(".notice").removeClass("d-none");
               setMsg("Something went wrong try again");
               removeMsg();
               reject();
            }
          });

          ajax.catch((error)=>{
            if(error)
            {
              $(".notice").removeClass("d-none");
              setMsg("Something went wrong try again");
              removeMsg();
              reject();
            }
          });
      }


        return (
            <div className="ms-content-wrapper">
              {pageLoader===false ? <CustomPageloader/> :

                <div className="row">
                  <Popup msg={msg}/>
                    <div className="col-md-12">
                        <div className="ms-panel">

                            <div className="ms-panel-header">
                              {alldata.length !== 0 &&
                                <div className="pr-4">
                                  <video src={videoUrl} controls="controls" style={{width:"100%",height:"300px"}}>
                                  </video>
                                </div>
                              }
                            </div>

                            <div className="ms-panel-body">
                              {alldata.length !== 0 &&
                              <MaterialTable
                                title="Splash Screen Video"
                                columns={columns}
                                data={alldata}
                                actions={[
                                  //{icon: 'visibility',tooltip: 'View Text',onClick: (event, rowData) => {getVideo(rowData.tableData.id)}},
                                  {icon: 'delete',tooltip: 'Delete splash video',onClick: (event, rowData) => {deleteData(rowData.tableData.id)}},
                                  // rowData => ({
                                  //   icon: () =><Link to={`/add-video-content/?id=${rowData._id}`}><span className="material-icons" style={{fontSize:"20px"}}>create</span></Link>,
                                  //   tooltip: 'Edit ',
                                  //   onClick: (rowData)
                                  // })
                                ]}
                                options={{
                                  actionsColumnIndex: -1
                                }}

                                editable={{
                                  onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve, reject) => {
                                      setTimeout(() => {
                                        const index = oldData.tableData.id;
                                        updateVideo(newData,oldData,resolve,reject,index);
                                      }, 1000)
                                    })
                                }}

                                />
                             }

                           <div>
                             {alldata.length === 0 &&
                             <form onSubmit={createAppVideocontent}>
                               <div className="row">

                                 <div className="col-md-6">
                                   <div className="form-group">
                                     <label>Video Tag</label>
                                     <input type="text" className="form-control rounded-0" value={inputData.videoTag} name="videoTag"  onChange={handleInput} required="required"/>
                                   </div>
                                 </div>

                                 <div className="col-md-6">
                                   <div className="form-group">
                                     <label>Video Url</label>
                                     <input type="text" className="form-control rounded-0" value={inputData.videoUrl} name="videoUrl"  onChange={handleInput} required="required"/>
                                   </div>
                                 </div>

                                 <div className="col-md-6">
                                   <div className="form-group">
                                     <label>Version</label>
                                     <input type="text" className="form-control rounded-0" value={inputData.version} name="version"  onChange={handleInput} required="required"/>
                                   </div>
                                 </div>

                                 <div className="col-md-6">
                                   <div className="form-group">
                                     <label>Status</label>
                                     <select className="form-control rounded-0" value={inputData.status} name="status"  onChange={handleInput}>
                                       <option value={true}>Active</option>
                                       <option value={false}>Deactive</option>
                                     </select>
                                   </div>
                                 </div>

                                 <div>
                                   <button className="btn btn-danger rounded-0"  type="submit">
                                     Add video
                                   </button>
                                 </div>

                               </div>

                             </form>
                           }

                           </div>
                        </div>
                        </div>
                    </div>

                </div>
              }
                <div className={loader ? '' : 'd-none'}>
                  <CustomRequestLoader/>
                </div>
            </div>

        );

}

export default ShowContent;
